import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import TestimonialsCarousel from '../../components/TestimonialsCarousel';
import './testimonials.scss';

export const Testimonials = () => {
  const { t } = useTranslation();

  return (
    <section className="testimonials">
      <div className="wrapper-medium">
        <section className="testimonials__content">
          <h3 className="h3">
            <Trans
              i18nKey="testimonials.title"
              components={{ newLine: <br />, bold: <span className="h3-book"></span> }}
            />
          </h3>
          <section className="testimonials__carousel">
            <TestimonialsCarousel></TestimonialsCarousel>
          </section>
        </section>
      </div>
    </section>
  );
};


