import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Highlight from '../../components/Highlight';
import PlansGrid from '../../components/PlansGrid';

import './plans.scss';

export const Plans = () => {
  const { t } = useTranslation();

  return (
    <section className="plans" id="precios">
      <div className="wrapper-big">
        <section
          className="plans__content"
          data-aos="fade-left"
          data-aos-duration="750"
          data-aos-once="true"
          data-aos-delay="500"
        >
          <section className="plans__header">
            <h2 className="h1 plans__title">{t('plans.title')}</h2>
            <p className="big plans__subtitle">
              <Trans
                i18nKey="plans.subtitle"
                components={{ bold: <span className="bold"></span> }}
              />
            </p>
          </section>
          <PlansGrid></PlansGrid>
          <section className="plans__highlight">
            <Highlight>{t('plans.highlight')}</Highlight>
          </section>
        </section>
      </div>
    </section>
    
  );
};
