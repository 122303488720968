import { graphql } from 'gatsby';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { subscriptionWizardFiles } from '../constants';
import SubscriptionSection from '../sections/Subscription';

const Subscription = () => {
  const [renderApp, setRenderApp] = useState(false);

  useEffect(() => {
    setRenderApp(true);
  }, []);

  if (!renderApp) {
    return null;
  }

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Anton&amp;family=Lato:wght@300;400;700;900&amp;display=swap"
        />
        <script src="https://cdn.zenrise.io/libraries/js/sdk/zenrise-sdk-latest.js"></script>
        {subscriptionWizardFiles.map(file =>
          file.type === 'script' ? (
            <script key={file.src} src={file.src} />
          ) : (
            <link key={file.src} href={file.src} rel="stylesheet" />
          ),
        )}
      </Helmet>
      <SubscriptionSection>
        <app-root></app-root>
      </SubscriptionSection>
    </>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Subscription;
