import React from 'react';
import './privacy-policies.scss';

export const PrivacyPolicies = () => {
  return (
    <section className="privacy-policies">
      <div className="wrapper-medium">
        <section className="privacy-policies__container">
          <section className="privacy-policies__header">
            <div></div>
            <h1 className="h1">Políticas de Privacidad</h1>
          </section>
          <section className="privacy-policies__content">
            <ul className="privacy-policies__navigation">
              <li>
                <a href="#cuestiones-previas">Cuestiones previas</a>
              </li>
              <li>
                <a href="#definiciones">Definiciones</a>
              </li>
              <li>
                <a href="#clausulas">Cláusulas</a>
                <ol>
                  <li>
                    <a href="#principio-protectorio">
                      Principio protectorio de la información de o datos personales
                    </a>
                  </li>
                  <li>
                    <a href="#recoleccion-informacion">
                      Recolección u utilización de su información
                    </a>
                  </li>
                  <li>
                    <a href="#informacion-personal">Información Personal</a>
                  </li>
                  <li>
                    <a href="#almacenamiento-informacion">Almacenamiento de la información</a>
                  </li>
                  <li>
                    <a href="#gestion-datos">Gestión de datos suministrados</a>
                  </li>
                  <li>
                    <a href="#compartir-informacion">Facultad de compartir su información</a>
                  </li>
                  <li>
                    <a href="#proteccion-informacion">Protección de su información</a>
                  </li>
                  <li>
                    <a href="#menores-edad">Menores de edad</a>
                  </li>
                  <li>
                    <a href="#uso-correo-electronico">Uso del correo electrónico del usuario</a>
                  </li>
                  <li>
                    <a href="#cookies">Cookies</a>
                  </li>
                  <li>
                    <a href="#links-externos">Links externos</a>
                  </li>
                  <li>
                    <a href="#derechos-usuario">Derechos del Usuario</a>
                  </li>
                  <li>
                    <a href="#facultades-pedix">Facultades de PEDIX</a>
                  </li>
                  <li>
                    <a href="#deber-autoridades">Deber de colaboración con autoridades</a>
                  </li>
                  <li>
                    <a href="#cambios-politica">Cambios a esta Política de Privacidad</a>
                  </li>
                  <li>
                    <a href="#contacto">Contacto</a>
                  </li>
                  <li>
                    <a href="#ley-aplicable">Ley aplicable</a>
                  </li>
                </ol>
              </li>
            </ul>
            <section className="privacy-policies__text">
              <section className="privacy-policies__section">
                <h4 className="privacy-policies__title h4" id="cuestiones-previas">
                  SECCIÓN PRIMERA: CUESTIONES PREVIAS.
                </h4>
                <p>
                  El presente documento contiene la Política de Privacidad respecto del sitio web
                  info.pedix.app (en adelante, “el Sitio”), explotado comercialmente por REM
                  Softory S.A.S CUIT 33-71715193-9, (en adelante, “PEDIX”) con domicilio en
                  Donaciano del Campillo 1312 de la ciudad de Córdoba, Provincia de Córdoba,
                  Argentina. -
                </p>
              </section>
              <section className="privacy-policies__section">
                <h4 className="privacy-policies__title h4" id="definiciones">
                  SECCIÓN SEGUNDA: DEFINICIONES.
                </h4>
                <p>
                  <strong>Pedix - La Empresa:</strong> Es la empresa que explota comercialmente el
                  Sitio, y que brinda a sus usuarios un servicio de intermediación mediante una
                  plataforma digital. Asimismo será quien administrará los datos proporcionados. -
                </p>
                <p>
                  <strong>Servicio:</strong> consiste en brindar un sistema autoadministrable para
                  crear catálogos independientes en línea, para dar visibilidad a los productos y/o
                  servicios ofrecidos de los usuarios y agilizar las consultas que tengan de manera
                  directa por WhatsApp, mediante un enlace suministrado por PEDIX. -
                </p>
                <p>
                  <strong>Usuario (Comerciante):</strong> Es toda persona humana mayor de dieciocho
                  (18) años, o persona jurídica que haya completado el proceso de alta como usuario
                  en el sitio de forma exitosa. -
                </p>
                <p>
                  <strong>Cliente del usuario:</strong> Es toda persona humana mayor de dieciocho
                  (18) años, o persona jurídica, que contrate productos o servicios del usuario a
                  través de la plataforma, como destinatario final, en su propio beneficio. -
                </p>
                <p>
                  <strong>Plataforma:</strong> Es el sistema de servicios dispuesto por PEDIX para
                  que el usuario exhiba los productos y servicios catalogados y en existencia
                  (stock) de forma remota. Dicha plataforma se encuentra operativa a través del
                  sitio info.pedix.app. -
                </p>
                <p>
                  <strong>Producto/s:</strong> Es el bien u objeto, mueble o inmueble, de existencia
                  comercial, catalogado por el Usuario y que éste ofrece a sus clientes a través de
                  la Plataforma. -
                </p>

                <p>
                  <strong>Servicio/s:</strong> Actividad remunerada directa o indirectamente,
                  suministrada, por el Usuario, cualquiera sea su objeto, que tenga por finalidad
                  satisfacer necesidades de sus clientes, en su carácter de destinatarios finales de
                  los mismos. –
                </p>
                <p>
                  <strong>Cookies:</strong> Archivos digitales de texto instalados por los
                  servidores en el disco rígido del dispositivo del usuario, que sirve a los fines
                  de registrar y almacenar datos del usuario, para recordar las preferencias del
                  usuario en su navegación habitual. -
                </p>
                <p>
                  <strong>Datos personales:</strong> Información de cualquier tipo referida a las
                  personas físicas o de existencia ideal determinadas o determinables. -
                </p>
                <p>
                  <strong>Datos comerciales:</strong> Información de cualquier tipo referida a la
                  actividad comercial / empresarial de las personas físicas o de existencia ideal
                  determinadas o determinables.
                </p>
                <p>
                  <strong>Datos sensibles:</strong> Aquellos datos personales que revelan origen
                  racial; étnico; opiniones políticas; convicciones o creencias religiosas,
                  filosóficas, morales; afiliaciones sindicales; información referente a la salud; e
                  información referente a la vida sexual. -
                </p>
                <p>
                  <strong>
                    En caso de que alguno de los términos utilizados en los presentes no tengan una
                    definición específica tendrán el significado que se les asigna en los Términos y
                    Condiciones del Sitio. -
                  </strong>
                </p>
              </section>
              <section className="privacy-policies__section">
                <h4 className="privacy-policies__title h4" id="clausulas">
                  SECCION TERCERA: CLÁUSULAS.
                </h4>
                <ol>
                  <li>
                    <h6 className="h6" id="principio-protectorio">
                      Principio protectorio de la información de o datos personales.
                    </h6>
                    <p>
                      Al brindarnos su información como Usuario, ud. deposita su confianza en PEDIX.
                      Es por ese motivo que PEDIX asume un compromiso de respeto y protección por la
                      privacidad de la información de toda aquella persona que acceda y/o permanezca
                      y/o utilice o despliegue alguna actividad dentro del Sitio. -
                    </p>
                    <p>
                      En el presente documento se detalla que información podrá ser recopilada por
                      PEDIX, así como también que uso se podrá dar a dicha información. Se explican
                      las medidas de seguridad tomadas para proteger la información, así como
                      también la posibilidad de acceder la misma. Finalmente se indica a quién podrá
                      contactar en PEDIX para que pueda evacuar sus dudas en relación con esta
                      Política de Privacidad, o bien para solucionar cualquier problema al respecto.
                      -
                    </p>
                  </li>
                  <li>
                    <h6 className="h6" id="recoleccion-informacion">
                      Recolección u utilización de su información.
                    </h6>
                    <ol>
                      <li>
                        <strong>RECOLECCIÓN DE INFORMACIÓN:</strong> PEDIX no recopilará a través
                        del Sitio información de ninguna clase sobre el Usuario que permita
                        identificarlo de forma personal, en adelante “Información Personal” (se
                        enumera a modo de ejemplo: nombre, dirección, número de teléfono o dirección
                        de correo electrónico), a menos que el Usuario la brinde de forma
                        consciente, libre y voluntaria. -
                        <p>
                          Si el Usuario no desea que dicha Información Personal sea recopilada,
                          deberá abstenerse de proporcionarla.{' '}
                          <strong>UD. NO ESTÁ OBLIGADO A SUMINISTRAR NINGUNA INFORMACIÓN.</strong>{' '}
                          Sin perjuicio de ello, se le informa a ud. que en caso de no proporcionar
                          su Información Personal, no podrá acceder a los servicios y/o información
                          disponibles a través del Sitio. -
                        </p>{' '}
                        <p>
                          <strong>
                            PEDIX en ningún momento recopilará información sensible sobre el
                            Usuario. -
                          </strong>
                        </p>
                      </li>
                      <li>
                        <strong>UTILIZACIÓN DE INFORMACIÓN:</strong> Se informa que la Información
                        Personal suministrada de forma voluntaria por el Usuario será objeto de
                        tratamiento automatizado e incorporada a la base de datos de PEDIX con la
                        finalidad de:
                        <div className="privacy-policies__list-inside">
                          <ul>
                            <li>
                              <span>1) </span>
                              Elaboración de estadísticas anónimas.-
                            </li>
                            <li>
                              <span>2) </span>
                              Implementación de mejoras en el Sitio.-
                            </li>
                            <li>
                              <span>3) </span>
                              Implementación de mejoras en el servicio proporcionado por PEDIX.-
                            </li>
                            <li>
                              <span>4) </span>
                              Cumplimiento de requerimientos, órdenes o mandas administrativas o
                              judiciales requeridas por autoridad competente por razones fundadas
                              relativas a disposiciones legales, la seguridad pública, la defensa
                              nacional o la salud pública.-
                            </li>
                          </ul>
                          <p>
                            <strong>
                              PEDIX se reserva el derecho de ampliar las finalidades a los fines del
                              tratamiento de los datos recolectados. -
                            </strong>
                          </p>
                        </div>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h6 className="h6" id="informacion-personal">
                      Información Personal.
                    </h6>
                    <ol>
                      <li>
                        La información recopilada puede incluir nombre completo, tipo y número de
                        documento, número de teléfono móvil o celular, dirección de correo
                        electrónico, nombre del comercio, negocio o empresa y/o, rubro comercial y/o
                        profesional, y eventualmente cualquier otra información que permita
                        individualizar al El Usuario a los fines de la utilización del servicio de
                        PEDIX. En todos los casos que el Usuario brinde Información Personal y
                        Comercial, y de acuerdo a la legislación vigente, el Usuario declara que la
                        Información Personal y Comercial brindada es correcta, cierta y actual. -
                      </li>
                      <li>
                        En los casos que nos brinde su Información Personal o Comercial, el Usuario
                        acepta y presta su consentimiento libre, expreso e informado para que dicha
                        Información Personal y/o Comercial sea utilizada con las finalidades
                        anteriormente mencionadas y autoriza a que la misma sea tratada, almacenada,
                        recopilada en las bases de datos de propiedad de PEDIX. En tal sentido, el
                        Usuario autoriza a que su Información Personal y/o comercial sea compartida
                        con los clientes del Usuario. Asimismo, el Usuario acepta y presta su
                        consentimiento libre, expreso e informado con los términos de la presente
                        Política de Privacidad. -
                      </li>
                      <li>
                        PEDIX recopila la Información Personal o Comercial en línea cuando:
                        <div className="privacy-policies__list-inside">
                          <ul>
                            <li>
                              <span>1) </span>
                              El Usuario se registra para hacer uso de alguno de los Servicios
                              disponibles del Sitio.-
                            </li>
                            <li>
                              <span>2) </span>
                              El Usuario utiliza el Sitio Web.-
                            </li>
                            <li>
                              <span>3) </span>
                              El Usuario nos envía preguntas, consultas o comentarios o se contacta
                              con el Sitio Web o solicita información.-
                            </li>
                            <li>
                              <span>4) </span>
                              El Usuario provee de cualquier forma información al Sitio Web (chats,
                              foros, uploads, etc.).-
                            </li>
                            <li>
                              <span>5) </span>
                              El cliente del usuario concluya su pedido.-
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h6 className="h6" id="almacenamiento-informacion">
                      Almacenamiento de la información.
                    </h6>
                    <p>
                      Los Datos Personales y/o comerciales suministrados personal y voluntariamente
                      por el Usuario serán almacenados en servidores “nube” utilizando a tal fin los
                      servicios de Google Cloud y/o Firebase Platform. -
                    </p>
                  </li>
                  <li>
                    <h6 className="h6" id="gestion-datos">
                      Gestión de datos suministrados.
                    </h6>
                    <p>
                      Los Datos Personales y/o Comerciales que PEDIX recolectare conforme lo
                      establecido en el presente documento podrán ser almacenados, procesados y
                      transferidos exclusivamente por:
                    </p>
                    <div className="privacy-policies__list-inside">
                      <ul>
                        <li>
                          <span>1) </span>
                          PEDIX.-
                        </li>
                        <li>
                          <span>2) </span>
                          Toda persona humana o jurídica con la que PEDIX celebrare un contrato de
                          transferencia o cesión de uno o más Datos Personales.-
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <h6 className="h6" id="compartir-informacion">
                      Facultad de compartir su información.
                    </h6>
                    <ol>
                      <li>
                        La Información Personal que el Usuario suministre voluntariamente será
                        reputada y tratada como confidencial. Sin perjuicio de ello, PEDIX podrá
                        compartir total o parcialmente la Información Personal suministrada por el
                        Usuario, incluyendo registros de comportamiento y actividad en el Sitio, o
                        contactos con terceros en cumplimiento de requerimientos, órdenes o mandas
                        administrativas o judiciales requeridas por autoridad competente por razones
                        fundadas relativas a disposiciones legales, la seguridad pública, la defensa
                        nacional o la salud pública. -
                      </li>
                      <li>
                        La Información Personal recabada a través del Sitio puede ser transferida
                        para los fines anteriormente descritos a nuestras filiales, entidades
                        asociadas y proveedores de servicios, en otros países. Dichos países no
                        tienen la misma normativa de protección de datos. Cuando transfiramos
                        Información Personal del Usuario a otros países, la protegeremos en la forma
                        descrita en este documento. -
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h6 className="h6" id="proteccion-informacion">
                      Protección de su información.
                    </h6>
                    <ol>
                      <li>
                        A los fines de prevenir el acceso no autorizado, mantener la fidelidad de
                        los datos suministrados, asegurando la confidencialidad y el uso correcto de
                        su Información Personal, PEDIX implementa medios físicos, electrónicos,
                        tecnológicos, administrativos y procedimentales en materia de seguridad para
                        resguardar y asegurar la Información Personal recopilada. PEDIX resguarda la
                        Información Personal de acuerdo a estándares y procedimientos de seguridad
                        establecidos. En mira del resguardo de los datos brindados, PEDIX evalúa
                        constantemente nuevas tecnologías para proteger esa información. PEDIX hace
                        presente que los procesos internos propios de las bases de datos cumplen con
                        las obligaciones legales de seguridad y confidencialidad impuestas por las
                        leyes de cada país en materia de privacidad y protección de datos
                        personales. -
                      </li>
                      <li>
                        Sin perjuicio de lo anteriormente expuesto, el Usuario reconoce que los
                        medios técnicos existentes que brindan seguridad no son infalibles o
                        inexpugnables y que aun cuando PEDIX adopta todos los recaudos razonables a
                        su alcance en materia de seguridad es posible sufrir manipulaciones,
                        destrucción y/o pérdida de información. De presentarse estos casos, PEDIX
                        procederá conforme las leyes de cada país en materia de privacidad y
                        protección de datos lo requieran. -
                      </li>
                      <li>
                        Todos los integrantes de PEDIX, ya sean sus directivos, empleados,
                        representantes, y toda aquella persona o entidad vinculada con la Empresa
                        comprenden y cumplen con estos estándares en materia de protección de datos
                        personales y seguridad de la información, asumiendo estrictos compromisos de
                        confidencialidad en cuanto a la Información Personal que procesan en el
                        ejercicio de sus funciones. -
                      </li>
                      <li>
                        El Usuario comprende, consiente, reconoce y acepta que su información
                        personal sea almacenada en la jurisdicción de PEDIX o que la misma pueda ser
                        transferida, almacenada y tratada fuera de país de residencia. La
                        Información Personal recabada a través del Sitio sólo podrá ser transferida
                        a nuestras filiales, entidades asociadas y proveedores de servicios en otros
                        países para los fines anteriormente descritos reconociendo que en dichos
                        países no existe la misma normativa de protección de datos. En caso de
                        transferencia de su Información Personal a otros países, la protegeremos en
                        la forma descripta en este documento y de acuerdo con la Ley de Protección
                        de Datos Personales y su marco regulatorio. -
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h6 className="h6" id="menores-edad">
                      Menores de edad.
                    </h6>
                    <p>
                      Atento que PEDIX solo se vincula con personas jurídicas o humanas mayores de
                      edad, deje expresamente establecido que no tiene interés de recopilar
                      Información Personal de menores de edad. En caso de que PEDIX tome
                      conocimiento certero de que la persona que se contrató o intenta contratar los
                      servicios de PEDIX es menor de edad, se procederá a advertirle que se abstenga
                      de brindar esa información Personal en el Sitio y tomará medidas razonables
                      para poner en conocimiento de sus padres, tutores o representantes legales el
                      hecho suscitado. -
                    </p>
                  </li>
                  <li>
                    <h6 className="h6" id="uso-correo-electronico">
                      Uso del correo electrónico del usuario.
                    </h6>
                    <p>
                      PEDIX podrá enviar al Usuario correos electrónicos en relación con el
                      contenido del Sitio, los servicios prestados por PEDIX o sobre su Cuenta y en
                      respuesta a sus preguntas, pedidos, consultas o comentarios. También le podrá
                      enviar correos electrónicos con información sobre actualizaciones de los
                      productos y/o servicios ofrecidos, o sobre nuevos productos y/o servicios
                      ofrecidos por PEDIX y/o terceros asociados comercialmente que le puedan
                      resultar de interés,{' '}
                      <strong>
                        salvo que el Usuario indique expresamente que no desea recibir dichos
                        correos electrónicos. A tal fin el Usuario deberá enviar un mail a
                        <a href="mailto:soporte@pedixapp.com" target="_blank" rel="noreferrer">
                          soporte@pedixapp.com
                        </a>
                        . –
                      </strong>
                    </p>
                    <p>
                      El Usuario podrá solicitar en cualquier momento el retiro o bloqueo de su
                      nombre de la base de datos a la que se refiere la presente Política de
                      Privacidad siguiendo el procedimiento señalado en esta misma política. -
                    </p>
                    <p>
                      En todo correo electrónico que el Usuario reciba de PEDIX se le informará cómo
                      rechazar el envío de futuros correos electrónicos promocionales. –
                    </p>
                    <p>
                      Sin perjuicio de lo anteriormente expuesto,{' '}
                      <strong>
                        PEDIX recomienda enfáticamente no dejar de recibir ningún correo mientras
                        ud. mantenga una relación comercial con PEDIX, atento a la potencial
                        importancia de la información enviada. -
                      </strong>
                    </p>
                  </li>
                  <li>
                    <h6 className="h6" id="cookies">
                      Cookies.
                    </h6>
                    <p>
                      Al ingresar en el Sitio Web, PEDIX puede almacenar alguna información en su
                      computadora y/o dispositivo bajo la forma de “Cookie” o archivo similar que
                      puede ser de utilidad. El Usuario puede, a través de su explorador de
                      internet, borrar las Cookies del disco rígido de su computadora y/o
                      dispositivo, bloquearlas o recibir alertas antes de que se almacene alguna
                      Cookie.{' '}
                      <strong>
                        Por favor, consulte las instrucciones de su explorador para conocer más
                        sobre estas funciones. -
                      </strong>
                    </p>
                  </li>
                  <li>
                    <h6 className="h6" id="links-externos">
                      Links externos.
                    </h6>
                    <p>
                      El Sitio puede contener links hacia y provenientes de otros sitios o
                      plataformas de Internet. En ese contexto, PEDIX no es responsable por las
                      prácticas de privacidad ni el tratamiento de los datos personales, ya sea
                      antes del acceso, durante la permanencia o después de la salida de esos otros
                      sitios o plataformas. En base a ello PEDIX advierte y recomienda enfáticamente
                      al USUARIO que consulte las prácticas de privacidad de dichos sitios de
                      Internet antes de su utilización. Queda expresamente establecido que la
                      presente Política de Privacidad y Protección de Datos Personales se aplica
                      solamente a PEDIX y NO se aplica a ningún otro sitio, plataforma o enlace de
                      terceros. -
                    </p>
                  </li>
                  <li>
                    <h6 className="h6" id="derechos-usuario">
                      Derechos del Usuario.
                    </h6>
                    <ol>
                      <li>
                        El Usuario podrá acceder a su perfil de usuario, revisar, modificar,
                        eliminar y actualizar su Información Personal en el momento que lo desee. -
                      </li>
                      <li>
                        Si el Usuario desea acceder a su Información Personal, o si tal Información
                        Personal es incorrecta, desactualizada y/o suprimida, deberá enviar su
                        solicitad desde su correo electrónico registrado con el asunto “Informar”,
                        “Rectificar”, “Suprimir” y/o “Actualizar” según corresponda, conjuntamente
                        con el objeto de su requerimiento. Dicha solicitud deberá contener como
                        mínimo la siguiente información:
                        <div className="privacy-policies__list-inside">
                          <ul>
                            <li>
                              <span>1) </span>
                              Nombre y domicilio del Usuario, a efecto de dar respuesta a su
                              solicitud en los plazos establecidos en la ley aplicable.-
                            </li>
                            <li>
                              <span>2) </span>
                              Copia del documento de identidad, pasaporte o cualquier otro documento
                              del Usuario que acredite su identidad o acredite la personalidad de su
                              representante legal, y en caso que la solicitud se realice a través
                              del representante legal, acompañar el documento que acredita sus
                              facultades como representante.-
                            </li>
                            <li>
                              <span>3) </span>
                              Descripción clara y precisa de los datos personales sobre los que se
                              busca ejercer alguno de los derechos antes mencionados de acceso,
                              rectificación, oposición, cancelación o revocación, así como la
                              descripción clara y precisa de su solicitud.-
                            </li>
                            <li>
                              <span>4) </span>
                              Cualquier otro elemento o documento que facilite la localización de
                              dichos datos personales.-
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li>
                        El derecho de acceso a los datos podrá ser ejercido en forma gratuita por el
                        Usuario en la medida que la correspondiente información sea solicitada en
                        intervalos no inferiores a seis (6) meses, salvo que se acredite un interés
                        legítimo al efecto. -
                      </li>
                      <li>
                        El contacto para ejercer los derechos de acceso, rectificación,
                        actualización y supresión de la Información Personal es:{' '}
                        <a href="mailto:soporte@pedixapp.com" target="_blank" rel="noreferrer">
                          soporte@pedixapp.com
                        </a>
                        . -
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h6 className="h6" id="facultades-pedix">
                      Facultades de PEDIX.
                    </h6>
                    <ol>
                      <li>
                        PEDIX no estará obligada a responder favorablemente a una solicitud recibida
                        del Usuario de acceso, rectificación y/o supresión de uno o más Datos
                        Personales, si el cumplimiento de la misma alterare o afectare de alguna
                        forma intereses de la Nación, el orden y la seguridad públicos o de los
                        derechos e intereses de terceros. -
                      </li>
                      <li>
                        PEDIX no estará obligada a responder favorablemente una solicitud recibida
                        del Usuario de acceso de uno o más Datos Personales si dicha solicitud no
                        estuviere fechada por lo menos 6 (seis) meses después de la fecha de la
                        última solicitud de acceso de Datos Personales que PEDIX hubiera recibido
                        del Usuario, excepto que éste demostrare razonablemente y acreditaré PEDIX
                        que tiene un interés legítimo para acceder a los Datos Personales de que se
                        trate antes de transcurrir 6 (seis) meses desde de la fecha de aquella
                        última solicitud de acceso. -
                      </li>
                      <li>
                        PEDIX no estará obligada a responder favorablemente a una solicitud recibida
                        del Usuario de supresión de uno o más Datos Personales, si la supresión
                        solicitada pudiere causar perjuicios a derechos o intereses legítimos de
                        terceros o impidiere a PEDIX cumplir una obligación legal de conservar los
                        Datos Personales de que se trate. -
                      </li>
                      <li>
                        En todos los casos contemplados en el presente artículo PEDIX informará de
                        forma concluyente los fundamentos de cada negativa mediante una comunicación
                        dirigida a la dirección de correo electrónico que el Usuario hubiera
                        informado en la solicitud que fuera rechazada. -
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h6 className="h6" id="deber-autoridades">
                      Deber de colaboración con autoridades.
                    </h6>
                    <p>
                      Queda expresamente establecido que PEDIX cooperará con las autoridades de cada
                      país cuando éstas requieran formalmente cualquier información relacionada con
                      nuestras bases de datos. -
                    </p>
                  </li>
                  <li>
                    <h6 className="h6" id="cambios-politica">
                      Cambios a esta Política de Privacidad.
                    </h6>
                    <p>
                      PEDIX se reserva el derecho a modificar esta Política de Privacidad
                      periódicamente, en cuyo caso la política actualizada se publicará en este
                      mismo sitio, siendo obligación del Usuario revisar regularmente esta sección a
                      fin de informarse de cualquier cambio que se pueda haber producido. De todos
                      modos, PEDIX cursará una comunicación a la cuenta de correo electrónico
                      registrada a efectos de informarle sobre el cambio en la Política de
                      Privacidad. -
                    </p>
                    <p>
                      El Usuario no estará obligado a aceptar, total o parcialmente, ninguna
                      modificación de esta Política de Privacidad y Protección de Datos Personales
                      realizada por PEDIX. En ese caso deberá, en el término de diez (10) días
                      corridos desde el conocimiento de la modificación, manifestar expresamente no
                      aceptar la modificación de esta Política de Privacidad y Protección de Datos
                      Personales. Tal manifestación deberá efectuarse mediante correo electrónico
                      enviado desde la dirección registrada como usuario. Ante esta negativa deberá
                      abstenerse de Acceder al Sitio y no deberá Permanecer en el Sitio o realizar
                      la Actividad en el Sitio. -
                    </p>
                  </li>
                  <li>
                    <h6 className="h6" id="contacto">
                      Contacto.
                    </h6>
                    <p>
                      Si tiene preguntas sobre su privacidad cuando utilice el Sitio, o ejercer los
                      derechos de acceso, rectificación, actualización y supresión de la Información
                      Personal, por favor contáctenos a los siguientes{' '}
                      <strong>
                        correos electrónicos:{' '}
                        <a href="mailto:soporte@pedixapp.com" target="_blank" rel="noreferrer">
                          soporte@pedixapp.com
                        </a>
                        . -
                      </strong>
                    </p>
                  </li>
                  <li>
                    <h6 className="h6" id="ley-aplicable">
                      Ley aplicable.
                    </h6>
                    <p>
                      Esta Política de Privacidad y Protección de Datos Personales se rige
                      exclusivamente por la ley de la República Argentina.
                    </p>
                  </li>
                </ol>
              </section>
            </section>
          </section>
        </section>
      </div>
    </section>
  );
};
