export function sortItemsByExplicitOrder<T extends { id: string }>(
  items: T[],
  itemsOrder: string[],
): T[] {
  const itemsMap = items.reduce((map, item) => map.set(item.id, item), new Map<string, T>());
  const sortedItems = [];

  itemsOrder.forEach(itemId => {
    if (itemsMap.has(itemId)) {
      sortedItems.push(itemsMap.get(itemId));

      itemsMap.delete(itemId);
    }
  });

  for (const item of itemsMap.values()) {
    sortedItems.push(item);
  }

  return sortedItems;
}
