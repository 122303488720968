import { StaticImage } from 'gatsby-plugin-image';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CartEmoji from '../../assets/images/emojis/cart.png';
import ComputerEmoji from '../../assets/images/emojis/computer.png';
import GlassesEmoji from '../../assets/images/emojis/glasses.png';
import HandsEmoji from '../../assets/images/emojis/hands.png';
import PhoneEmoji from '../../assets/images/emojis/phone.png';
import Button from '../../components/Button';
import GlobalContext from '../../context/GlobalContext';
import { faqsClickedEvent } from '../../utils/analytics';
import './faqs.scss';

interface IQuestions {
  icon: string;
  title: string;
  content: string;
  isOpen: boolean;
}

export const Faqs = () => {
  const { t } = useTranslation();
  const { handleOpenChat } = useContext(GlobalContext);
  const [questions, setQuestions] = useState<IQuestions[]>([
    {
      icon: GlassesEmoji,
      isOpen: true,
      title: t('faqs.faqOneTitle'),
      content: t('faqs.faqOneContent'),
    },
    {
      icon: ComputerEmoji,
      isOpen: false,
      title: t('faqs.faqTwoTitle'),
      content: t('faqs.faqTwoContent'),
    },
    {
      icon: CartEmoji,
      isOpen: false,
      title: t('faqs.faqThreeTitle'),
      content: t('faqs.faqThreeContent'),
    },
    {
      icon: HandsEmoji,
      isOpen: false,
      title: t('faqs.faqFourTitle'),
      content: t('faqs.faqFourContent'),
    },
    {
      icon: PhoneEmoji,
      isOpen: false,
      title: t('faqs.faqFiveTitle'),
      content: t('faqs.faqFiveContent'),
    },
  ]);

  const handleFaqClick = (event: any, question: IQuestions, index: number) => {
    event.preventDefault();

    const updatedQuestions = [...questions];
    updatedQuestions[index] = { ...question, isOpen: !question.isOpen };

    setQuestions(updatedQuestions);
  };

  const handleMoreQuestions = () => {
    faqsClickedEvent();
    handleOpenChat();
  };

  return (
    <section className="faqs" id="preguntas-frecuentes">
      <div className="wrapper-medium">
        <section className="faqs__content">
          <section
            className="faqs__text"
            data-aos="fade-down"
            data-aos-duration="750"
            data-aos-once="true"
            data-aos-delay="500"
          >
            <h2 className="h2 faqs__title">{t('faqs.title')}</h2>
            <section className="faqs__questions">
              {questions.map((faq, index) => (
                <details
                  className="questions"
                  open={faq.isOpen}
                  onClick={event => handleFaqClick(event, faq, index)}
                  key={index}
                >
                  <summary className="questions__title-content">
                    <span>
                      <img src={faq.icon} alt="" />
                      <h3 className="questions__title">{faq.title}</h3>
                      <i className="ci-plus questions__icon questions__icon--plus"></i>
                      <i className="ci-minus questions__icon questions__icon--minus"></i>
                    </span>
                  </summary>
                  <p className="questions__text">{faq.content}</p>
                </details>
              ))}
            </section>
            <Button type="with-arrow" size="medium" onClick={handleMoreQuestions}>
              {t('general.moreQuestions')}
            </Button>
          </section>
          <section
            className="faqs__image-content"
            data-aos="fade-right"
            data-aos-duration="750"
            data-aos-once="true"
            data-aos-delay="1000"
          >
            <StaticImage
              src="../../assets/images/pedix-rubros.png"
              alt="Pedix Rubros"
              className="faqs__image"
              width={900}
              placeholder="blurred"
              quality={100}
            ></StaticImage>
          </section>
        </section>
      </div>
    </section>
  );
};
