import React from 'react';
import { ClientsLogosCarousel } from '../../components/ClientsLogosCarousel/ClientsLogosCarousel';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import './statistics.scss';
import { Trans, useTranslation } from 'react-i18next';

export const Statistics = () => {
  const [viewportEntered, setViewportEntered] = React.useState(false);
  const { t } = useTranslation();

  return (
    <section className="statistics">
      <div className="wrapper-medium">
        <section className="statistics__content">
          <section className="statistics__orders" data-aos="fade-up" data-aos-easing="ease-in">
            <h2 className="h1 statistics__orders-amount">
              <CountUp
                start={viewportEntered ? 0 : null}
                end={3000000}
                duration={4}
                separator="."
                decimals={0}
                delay={0}
              >
                {({ countUpRef }) => (
                  <VisibilitySensor
                    active={!viewportEntered}
                    onChange={isVisible => {
                      if (isVisible) {
                        setViewportEntered(true);
                      }
                    }}
                    delayedCall
                  >
                    <div>
                      + <span ref={countUpRef} />
                    </div>
                  </VisibilitySensor>
                )}
              </CountUp>
            </h2>
            <p className="big statistics__orders-amount-subtitle">
              <Trans i18nKey="statistics.subtitle" components={{ newLine: <br /> }} />
            </p>
          </section>
          <section className="statistics__clients">
            <p>
              { t('statistics.legend') }
            </p>
            <section className="statistics__logos">
              <ClientsLogosCarousel></ClientsLogosCarousel>
            </section>
          </section>
        </section>
      </div>
    </section>
  );
};
