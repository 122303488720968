import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import MantequitaLogo from '../../assets/images/logos/mantequita.png';
import BepetgoLogo from '../../assets/images/logos/bepetgo.png';
import ClubDelAntojoLogo from '../../assets/images/logos/clubdelantojo.png';
import ColombraroLogo from '../../assets/images/logos/colombraro.png';
import GlupsLogo from '../../assets/images/logos/glups.png';
import MaeLogo from '../../assets/images/logos/mae.png';
import CarthageLogo from '../../assets/images/logos/carthage.png';
import ADELogo from '../../assets/images/logos/ade.png';
import AlFornoLogo from '../../assets/images/logos/alforno.png';
import MRLogo from '../../assets/images/logos/mr.png';
import PezLogo from '../../assets/images/logos/pez.png';
import Punto33Logo from '../../assets/images/logos/punto33.png';
import RosaChinaLogo from '../../assets/images/logos/rosachina.png';
import SantaCalmaLogo from '../../assets/images/logos/santacalma.png';
import StregaLogo from '../../assets/images/logos/strega.png';
import StucerdoLogo from '../../assets/images/logos/stucerdo.png';
import TekneLogo from '../../assets/images/logos/tekne.png';
import TeppanyakiLogo from '../../assets/images/logos/teppanyaki.png';
import AmericanDonutsLogo from '../../assets/images/logos/americandonuts.png';

import 'swiper/swiper.min.css';
import './clients-logos-carousel.scss';

interface IClientLogosCarousel {
  logo: string;
  url?: string;
  background: string;
  alt: string;
}

export const ClientsLogosCarousel = () => {
  const [clients, setClients] = useState<IClientLogosCarousel[]>([
    {
      background: '#474A57',
      logo: MantequitaLogo,
      alt: 'Carnes La Mantequita',
    },
    {
      background: 'white',
      logo: BepetgoLogo,
      alt: 'Bepetgo!',
    },
    {
      background: '#0E2B45',
      logo: PezLogo,
      alt: 'Pez - Almacén de Congelados ',
    },
    {
      background: '#0B0D17',
      logo: MRLogo,
      alt: 'MIR - Fitness',
    },
    {
      background: 'white',
      logo: MaeLogo,
      alt: 'MAE - Bebidas',
    },
    {
      background: '#E6007E',
      logo: GlupsLogo,
      alt: 'Glup"s',
    },
    {
      background: 'white',
      logo: TekneLogo,
      alt: 'Tekné',
    },
    {
      background: '#E07937',
      logo: StregaLogo,
      alt: 'Strega',
    },
    {
      background: '#FFD082',
      logo: ColombraroLogo,
      alt: 'Todo Colombraro',
    },
    {
      background: '#A1D8E2',
      logo: RosaChinaLogo,
      alt: 'Rosaa China',
    },
    {
      background: 'white',
      logo: StucerdoLogo,
      alt: 'Stucerdo',
    },
    {
      background: 'black',
      logo: Punto33Logo,
      alt: 'Punto 33',
    },
    {
      background: 'black',
      logo: AlFornoLogo,
      alt: 'Al Forno',
    },
    {
      background: '#1f1a18',
      logo: ADELogo,
      alt: 'ADE Construcciones',
    },
    {
      background: '#cbfbfb',
      logo: CarthageLogo,
      alt: 'Carthage',
    },
    {
      background: '#EDE0C6',
      logo: ClubDelAntojoLogo,
      alt: 'Club del Antojo',
    },
    {
      background: '#905F87',
      logo: AmericanDonutsLogo,
      alt: 'American Donuts',
    },
    {
      background: '#F57D7E',
      logo: SantaCalmaLogo,
      alt: 'Santa Calma',
    },
    {
      background: '#337987',
      logo: TeppanyakiLogo,
      alt: 'Teppanyaki',
    },
  ]);

  return (
    <Swiper
      breakpoints={{
        0: {
          slidesPerView: 2,
          slidesPerColumn: 4,
          spaceBetween: 24,
          slidesPerColumnFill: 'row',
        },
        450: {
          slidesPerView: 4,
          slidesPerColumn: 2,
          spaceBetween: 16,
          slidesPerColumnFill: 'row',
        },
      }}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
    >
      {clients.map((client, index) => (
        <SwiperSlide key={index}>
          <section
            className="clients-carousel__logo"
            style={{ backgroundColor: client.background }}
          >
            <img src={client.logo} alt={client.alt}></img>
          </section>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
