import { Country, LandingPromotions } from '@pedix-workspace/utils';

export const getPromotionCode = (
  landingPromotions: LandingPromotions[],
  country: Country,
): LandingPromotions => {
  if (!landingPromotions || country === 'AR') {
    return;
  }

  const urlParams = new URLSearchParams(window.location.search);
  const promotionCode = urlParams.get('utm_campaign');

  if (!promotionCode) {
    return;
  }

  const today = new Date().getTime();
  const landingPromotion = landingPromotions.find(
    promotion =>
      today >= new Date(promotion.startDate).getTime() &&
      today <= new Date(promotion.endDate).getTime() &&
      promotion.promotionCode === promotionCode,
  );

  return landingPromotion;
};
