import { Link } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useEffect } from 'react';
import Button from '../../components/Button';
import CirclesDecoration from '../../components/CirclesDecoration';
import './not-found.scss';

export const NotFound = () => {
  useEffect(() => {
    document.body.style.overflowX = 'hidden';
    return () => {
      document.body.style.overflowX = 'initial';
    };
  }, []);

  return (
    <section className="not-found">
      <div className="wrapper-medium">
        <section className="not-found__content">
          <CirclesDecoration></CirclesDecoration>
          <StaticImage
            src="./../../assets/images/rocket-2.png"
            alt="Cohete"
            className="not-found__rocket"
          ></StaticImage>
          <h1 className="h1 not-found__header">404</h1>
          <div className="not-found__text">
            <h3 className="h3-book">
              <StaticImage
                src="./../../assets/images/emojis/dude.png"
                alt="Dude"
                className="not-found__dude"
              ></StaticImage>
              ¿Perdido en <span>el espacio</span> Pedix?
            </h3>
            <p className="big">Parece que esta página no existe</p>
          </div>
          <div className="not-found__buttons">
            <Link to="/">
              <Button type="with-arrow-left">Volver a la home</Button>
            </Link>
            <Link to="/#precios" >
              <Button type="primary" fill="solid" size="large">
                Quiero empezar
              </Button>
            </Link>
          </div>
        </section>
      </div>
    </section>
  );
};
