import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { menuOptions } from '../../utils/menu';
import './footer.scss';
import { footerClickedEvent } from '../../utils/analytics';
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby-plugin-react-i18next';

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <div className="wrapper-medium">
        <section className="footer__content">
          <StaticImage
            className="footer__logo"
            src="../../assets/images/logo-white.png"
            alt="Pedix"
            placeholder="blurred"
            quality={100}
          ></StaticImage>
          <nav className="footer__nav">
            <ul>
              {menuOptions.map(
                (menuOption, index) =>
                  menuOption.visibleIn.includes('FOOTER') && (
                    <li key={index}>
                      <Link
                        to={menuOption.url}
                        className="footer__link"
                        title={t(menuOption.name)}
                        onClick={() => footerClickedEvent(menuOption.url)}
                      >
                        {t(menuOption.name)}
                      </Link>
                    </li>
                  ),
              )}
            </ul>
          </nav>
          <p className="footer__copyright tag">© 2021</p>
          <section className="footer__rrss">
            <a
              href="https://www.instagram.com/pedix_ok/"
              target="_blank"
              rel="noreferrer"
              onClick={() => footerClickedEvent('instagram')}
            >
              <i className="ci-instagram footer__icon"></i>
            </a>
            <a
              href="https://www.youtube.com/channel/UCW4GFBN3wKJP8mg9PtsUkAg"
              target="_blank"
              rel="noreferrer"
              onClick={() => footerClickedEvent('youtube')}
            >
              <i className="ci-youtube footer__icon"></i>
            </a>
          </section>
        </section>
      </div>
    </footer>
  );
};
