import React from 'react';
import CirclesDecoration from '../../components/CirclesDecoration';
import './subscription-section.scss';

export const SubscriptionSection = ({ children }) => {
  return (
    <section className="subscription-section">
      <section className="subscription-section__content">
        <CirclesDecoration></CirclesDecoration>
        <div className="subscription-section__circle-gray"></div>
        {children}
      </section>
    </section>
  );
};
