import { PlanFeatures, featuresList } from '@pedix-workspace/utils';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useContext, useState } from 'react';
import GlobalContext from '../../context/GlobalContext';
import Plan from '../Plan';
import arrow from '../../assets/images/arrow.png';

import './plans-grid.scss';
import { PaymentMethodType } from '../../types';
import { changeBillingTypeEvent } from '../../utils/analytics';
import { useTranslation } from 'react-i18next';
import PromotionAlert from '../PromotionAlert';

export const PlansGrid = () => {
  const [features] = useState<PlanFeatures[]>(featuresList);
  const { countryVisitor, promotionCode } = useContext(GlobalContext);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodType>('MONTLY');
  const { t } = useTranslation();

  const handleBillingTypeChange = billingType => {
    setPaymentMethod(billingType);
    changeBillingTypeEvent(billingType);
  };

  const {
    allPlans: { nodes: plans },
  } = useStaticQuery(graphql`
    {
      allPlans {
        nodes {
          id
          amounts {
            MONTLY {
              XX
              AR
              CL
              UY
              BR
              MX
            }
            YEARLY {
              XX
              AR
              CL
              UY
              BR
              MX
            }
          }
          prices {
            MONTLY {
              XX
              AR
              CL
              UY
              BR
              MX
            }
            YEARLY {
              XX
              AR
              CL
              UY
              BR
              MX
            }
          }
          name
          description
        }
      }
    }
  `);

  if (!countryVisitor) {
    return <></>;
  }

  return (
    <>
      <section className="switch-payment">
        <section className="switch-payment__container">
          <div className="switch-payment__promotion">
            <img src={arrow} alt="" className="switch-payment__promotion-arrow" />
            <span className="switch-payment__promotion-text">{t('plans.freeMonths')}</span>
          </div>
          <ul className="switch-payment__content">
            <li
              className={`switch-payment__option ${
                paymentMethod === 'MONTLY' && 'switch-payment__option--selected'
              }`}
              onClick={() => handleBillingTypeChange('MONTLY')}
            >
              {t('plans.monthly')}
            </li>
            <li
              className={`switch-payment__option ${
                paymentMethod === 'YEARLY' && 'switch-payment__option--selected'
              }`}
              onClick={() => handleBillingTypeChange('YEARLY')}
            >
              {t('plans.yearly')}
            </li>
          </ul>
        </section>
      </section>
      <PromotionAlert billingType={paymentMethod}></PromotionAlert>
      <section className="plans-grid">
        <ul className={`plans-grid__features ${promotionCode && `plans-grid__features--promotions`}`}>
          {features.map((feature, index) => (
            <li key={index}>
              <p className="semibold feature__name">{t(feature.name)}</p>
            </li>
          ))}
        </ul>
        <section className="plans-grid__plans">
          <Plan
            features={features}
            plan={plans.find(plan => plan.id === 'BASIC')}
            countryVisitor={countryVisitor}
            paymentMethod={paymentMethod}
            promotionCode={promotionCode?.codes.find(code => code.billingType === paymentMethod)}
          ></Plan>
          <Plan
            features={features}
            plan={plans.find(plan => plan.id === 'INTERMEDIATE')}
            countryVisitor={countryVisitor}
            paymentMethod={paymentMethod}
            promotionCode={promotionCode?.codes.find(code => code.billingType === paymentMethod)}
          ></Plan>
          <Plan
            features={features}
            plan={plans.find(plan => plan.id === 'ADVANCED')}
            countryVisitor={countryVisitor}
            paymentMethod={paymentMethod}
            promotionCode={promotionCode?.codes.find(code => code.billingType === paymentMethod)}
          ></Plan>
        </section>
      </section>
    </>
  );
};
