import { ANALYTICS_EVENTS } from '../constants';

declare const dataLayer: unknown[];

const sendEvent = (event, data) => {
  try {
    dataLayer.push({
      event,
      ...data
    })
  } catch (error) {
    console.log({ error });
  }
};

export const menuClickedEvent = menu_option => {
  sendEvent(ANALYTICS_EVENTS.HOMEPAGE_MENU_CLICKED, { menu_option });
};
export const demoViewedEvent = section => {
  sendEvent(ANALYTICS_EVENTS.HOMEPAGE_DEMO_VIEWED, { section });
};
export const pricingViewedEvent = section => {
  sendEvent(ANALYTICS_EVENTS.HOMEPAGE_PRICING_VIEWED, { section });
};
export const subscriptionStartedEvent = (plan_type, billing_type) => {
  sendEvent(ANALYTICS_EVENTS.HOMEPAGE_SUBSCRIPTION_STARTED, { plan_type, billing_type });
};
export const subsdiaryClickedEvent = plan_type => {
  sendEvent(ANALYTICS_EVENTS.HOMEPAGE_SUBSIDIARY_CLICKED, { plan_type });
};
export const faqsClickedEvent = () => {
  sendEvent(ANALYTICS_EVENTS.HOMEPAGE_FAQS_CLICKED, {});
};
export const footerClickedEvent = menu_option => {
  sendEvent(ANALYTICS_EVENTS.HOMEPAGE_FOOTER_CLICKED, { menu_option });
};
export const changeBillingTypeEvent = billing_type => {
  sendEvent(ANALYTICS_EVENTS.HOMEPAGE_BILLING_TYPE_CHANGED, { billing_type });
};
