import React, { useContext, useEffect, useState } from 'react';
import Button from '../Button';
import Modal from 'react-modal';
import { deviceDetector } from '../../utils/deviceDetector';
import { demoViewedEvent } from '../../utils/analytics';
import { useTranslation } from 'react-i18next';
import { useI18next } from 'gatsby-plugin-react-i18next';
import GlobalContext from '../../context/GlobalContext';
import { getCountryCode } from '@pedix-workspace/utils';

interface ITiendaDemo {
  size: string;
  location?: string;
}

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 5000,
    padding: '16px 0px !important',
    display: 'flex',
    flexDirection: 'column',
  },
};

export const TiendaDemo = ({ size, location }) => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [demoUrl, setDemoUrl] = useState('');
  const { t } = useTranslation();
  const { countryVisitor } = useContext(GlobalContext);

  useEffect(() => {
    const countryCode = getCountryCode(countryVisitor?.country);
    setDemoUrl(
      countryCode === 'CL'
        ? 'https://minimarket.pedix.app'
        : 'https://pedix.app/mercaditopedix',
    );
  }, []);

  const openModal = () => {
    if (location) {
      demoViewedEvent(location);
    }

    if (deviceDetector() === 'mobile') {
      window.open(demoUrl, '_blank');
      return;
    }

    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    if (modalIsOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [modalIsOpen]);

  return (
    <section className="tienda__demo">
      <Button type="with-arrow" size={size} onClick={openModal}>
        {t('general.buttonDemo')}
      </Button>

      {modalIsOpen && <i className="ci-close_big"></i>}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalStyles}
        contentLabel="Tienda demo"
        ariaHideApp={false}
      >
        <iframe
          src={demoUrl}
          style={{ border: 0 }}
          title="myiFrame"
          scrolling="no"
          frameBorder="0"
          marginHeight={0}
          marginWidth={0}
          height="600px"
          width="380px"
          allowFullScreen
        ></iframe>
        <Button
          type="primary"
          size="small"
          fill="solid"
          color="pink"
          onClick={() => setModalIsOpen(false)}
        >
          {t('general.close')}
        </Button>
      </Modal>
    </section>
  );
};
