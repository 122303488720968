export function flattenObject(
  object: Record<string, any>,
  prefix: string[] = [],
): Record<string, string> {
  return Object.entries(object).reduce((map, [key, value]) => {
    if (typeof value === 'undefined') {
      return map;
    }
    if (typeof value === 'object') {
      return Object.assign(map, flattenObject(value, [...prefix, key]));
    }
    return Object.assign(map, { [[...prefix, key].join('.')]: value });
  }, {});
}
