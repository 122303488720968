import { Country } from './types';

export const getCurrencySymbol = (countryCode: Country) => {
  const symbols = {
    AR: '$',
    UY: 'UYU',
    BR: 'R$',
    MX: 'MXN',
    CL: 'CLP',
    XX: 'USD',
  };

  return symbols[countryCode];
};
