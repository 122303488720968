/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

export default function Seo({ schema }) {
  const { t } = useTranslation();

  return (
    <Helmet
      htmlAttributes={{
        lang: 'es',
      }}
      title={t('siteMetadata.title')}
      titleTemplate={t('siteMetadata.title')}
      meta={[
        {
          name: `description`,
          content: t('siteMetadata.description'),
        },
        {
          property: `og:title`,
          content: t('siteMetadata.title'),
        },
        {
          property: `og:description`,
          content: t('siteMetadata.description'),
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: t('siteMetadata.author'),
        },
        {
          name: `twitter:title`,
          content: t('siteMetadata.title'),
        },
        {
          name: `twitter:description`,
          content: t('siteMetadata.description'),
        },
      ]}
    >
      {schema && <script type="application/ld+json">{JSON.stringify(schema)}</script>}
    </Helmet>
  );
}
