import React, { useContext, useEffect, useState } from 'react';
import { ICountryVisitor, IPlan, PaymentMethodType } from '../../types';
import Button from '../Button';
import FaceStarIcon from '../../assets/images/emojis/face-stars.png';
import {
  PlanFeature,
  getCurrencySymbol,
  getCountryCode,
  PlanType,
  LandingPromotionsCode,
} from '@pedix-workspace/utils';
import './plan.scss';
import { Link } from 'gatsby-plugin-react-i18next';
import GlobalContext from '../../context/GlobalContext';
import { subscriptionStartedEvent, subsdiaryClickedEvent } from '../../utils/analytics';
import { useIsFirstRender } from '../../hooks/IsFirstRender';
import { useTranslation } from 'react-i18next';

interface IPlanComponent {
  features: PlanFeature[];
  plan: IPlan;
  countryVisitor: ICountryVisitor;
  paymentMethod: PaymentMethodType;
  promotionCode: LandingPromotionsCode;
}

export const Plan: React.FC<IPlanComponent> = ({
  features,
  plan,
  countryVisitor,
  paymentMethod,
  promotionCode,
}) => {
  const isFirstRender = useIsFirstRender();
  const [bigText, setBigText] = useState<boolean>(false);
  const { handleOpenChat } = useContext(GlobalContext);
  const [isAdvanced] = useState(plan.name === 'ADVANCED');
  const [countryCode] = useState(getCountryCode(countryVisitor?.country));
  const { t } = useTranslation();

  useEffect(() => {
    if (!isFirstRender) {
      setBigText(true);
      setTimeout(() => {
        setBigText(false);
      }, 500);
    }
  }, [paymentMethod, isFirstRender]);

  const handleFeatureCustom = () => {
    subsdiaryClickedEvent(plan.description);
    handleOpenChat();
  };

  const renderFeatureContent = (feature: PlanFeature) => {
    const featureKey =
      plan.name === 'ADVANCED' &&
      paymentMethod === 'YEARLY' &&
      feature.planDetails['ADVANCED_ANNUAL']
        ? 'ADVANCED_ANNUAL'
        : plan.name;

    const featurePlanDetails = feature.planDetails[featureKey];

    if (featurePlanDetails?.custom) {
      return (
        <p className="link plan__more-information" onClick={handleFeatureCustom}>
          {t('general.knowMore')}
        </p>
      );
    }

    if (featurePlanDetails?.text) {
      return <p>{t(featurePlanDetails.text, featurePlanDetails.config)}</p>;
    }

    return featurePlanDetails?.display ? (
      <i className="ci-check plan__feature-icon plan__feature-icon--check"></i>
    ) : (
      <i className="ci-close_big plan__feature-icon plan__feature-icon--close"></i>
    );
  };

  const getPrice = () => {
    const price = plan.amounts[paymentMethod][countryCode];

    if (!promotionCode) {
      return price;
    }

    const discount = (price * promotionCode.discount) / 100;
    return Math.ceil(price - discount);
  };

  const getSubscriptionUrl = () => {
    let url = `/subscription#!/?countryCode=${countryCode}&planType=${plan.name}&billingType=${paymentMethod}`;

    if (promotionCode) {
      url += `&promotionCode=${promotionCode.code}`;
    }

    return url;
  };

  return (
    <section className={`plan  ${isAdvanced ? 'plan__hightlight' : ''}`}>
      {isAdvanced && (
        <h6 className="h6 plan__hightlight-text">
          <img src={FaceStarIcon} alt="icon face star" />
          {t('plans.mostSelected')}
          <img src={FaceStarIcon} alt="icon face star" />
        </h6>
      )}
      <section className="plan__header">
        <h6 className="h6 plan-header__name">{t(`plans.${plan.description}`)}</h6>
        <section className={promotionCode && 'plan-header__with-promotion'}>
          <section className="plan-header__price-content">
            <p className="big plan-header__symbol">{getCurrencySymbol(countryCode)}</p>
            <h3 className={`plan-header__price h2 ${bigText ? 'plan-header__price--big' : ''}`}>
              {plan.amounts[paymentMethod][countryCode]}
            </h3>
            <p className="plan-header__montly">
              {paymentMethod === 'MONTLY' ? t('plans.month') : t('plans.year')}
            </p>
          </section>
          {promotionCode && (
            <section className="plan-header__price-content">
              <p className="big plan-header__symbol">{getCurrencySymbol(countryCode)}</p>
              <h3 className={`plan-header__price h2 ${bigText ? 'plan-header__price--big' : ''}`}>
                {getPrice()}
              </h3>
              <p className="plan-header__montly">
                {paymentMethod === 'MONTLY' ? t('plans.month') : t('plans.year')}
              </p>
            </section>
          )}
        </section>
        <Link
          to={getSubscriptionUrl()}
          onClick={() => subscriptionStartedEvent(plan.description, paymentMethod)}
        >
          <Button type="with-arrow">{t('general.wantIt')}</Button>
        </Link>
      </section>
      <p className="plan__name">{t(`plans.${plan.description}`)}</p>
      <ul className="plan__features">
        {features.map((feature, index) => (
          <li key={index}>{renderFeatureContent(feature)}</li>
        ))}
      </ul>
      <section className="plan__footer">
        <h3 className="h3 plan-footer__price">
          <span>{getCurrencySymbol(countryCode)}</span>
          {plan.amounts[paymentMethod][countryCode]}
          {paymentMethod === 'MONTLY' ? t('plans.month') : t('plans.year')}
        </h3>
        <p className="plan-footer__aclaration">
          {paymentMethod === 'MONTLY'
            ? t('plans.formOfPaymentMonthly')
            : t('plans.formOfPaymentYearly')}
        </p>
        <Link
          to={getSubscriptionUrl()}
          onClick={() => subscriptionStartedEvent(plan.description, paymentMethod)}
        >
          <Button type={isAdvanced ? 'primary' : 'with-arrow'} size="medium" fill="solid">
            {t('general.wantIt')}
          </Button>
        </Link>
      </section>
    </section>
  );
};
