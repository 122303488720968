import { BillingType } from '@pedix-workspace/utils';
import React, { useContext } from 'react';
import GlobalContext from '../../context/GlobalContext';

import './promotion-alert.scss';

interface IPromotionAlert {
  billingType: BillingType;
}

export const PromotionAlert: React.FC<IPromotionAlert> = ({ billingType }) => {
  const { promotionCode } = useContext(GlobalContext);

  if (!promotionCode) {
    return <></>;
  }

  const getText = () => {
    const promotionCodeBillingType = promotionCode.codes.find(
      code => code.billingType === billingType,
    );

    let message = `${promotionCodeBillingType.discount}% de descuento `;

    if (billingType === 'MONTLY' && promotionCodeBillingType.quantityOfMonths === 1) {
      message += `en el primer mes.`;
    }

    if (billingType === 'MONTLY' && promotionCodeBillingType.quantityOfMonths > 1) {
      message += `durante ${promotionCodeBillingType.quantityOfMonths} meses.`;
    }

    if (billingType === 'YEARLY') {
      message += `por única vez.`;
    }

    return message;
  };

  return (
    <section className="promotion-code">
      <p className="promotion-code__text">{getText()}</p>
    </section>
  );
};
