import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import { pricingViewedEvent } from '../../utils/analytics';

import './community.scss';

export const Community = () => {
  const { t } = useTranslation();

  return (
    <section className="community">
      <div className="wrapper-big">
        <section className="community__content">
          <section
            className="community__join"
            data-aos="fade-right"
            data-aos-duration="750"
            data-aos-delay="500"
          >
            <StaticImage
              src="../../assets/images/rocket.png"
              alt="Cohete Pedix"
              placeholder="blurred"
              quality={100}
            ></StaticImage>

            <h2 className="h2 community__title">
              <Trans i18nKey="community.title" components={{ newLine: <br /> }} />
            </h2>
            <p className="big community__text">
              <Trans
                i18nKey="community.subtitle"
                components={{ bold: <span className="bold"></span> }}
              />
            </p>
            <Link
              to="/#precios"
              onClick={() => pricingViewedEvent('bottom banner')}
            >
              <Button type="with-arrow" className="community__button" size="medium">
                {t('general.wantJoin')}
              </Button>
            </Link>
          </section>
          <section
            className="community__photos"
            data-aos="fade-down"
            data-aos-duration="750"
            data-aos-delay="500"
          >
            <section className="community__photos--first-column">
              <StaticImage
                src="../../assets/images/community/babu.png"
                alt="Babu"
                className="community__photo"
                placeholder="blurred"
                quality={100}
              ></StaticImage>
              <StaticImage
                src="../../assets/images/community/gataflora.png"
                alt="Gataflora"
                className="community__photo"
                placeholder="blurred"
                quality={100}
              ></StaticImage>
            </section>
            <section className="community__photos--second-column">
              <StaticImage
                src="../../assets/images/community/stucerdo.png"
                alt="Stucerdo"
                className="community__photo"
                placeholder="blurred"
                quality={100}
              ></StaticImage>
              <StaticImage
                src="../../assets/images/community/triangulo.png"
                alt="Triangulo"
                className="community__photo"
                placeholder="blurred"
                quality={100}
              ></StaticImage>
            </section>
          </section>
        </section>
      </div>
    </section>
  );
};
