import { Timestamp } from "firebase-admin/firestore";
import { Establishment } from "./types";

export function getCurrentPeriodEndDate(subscription: Establishment['subscription']) {
  // If Zenrise could not make the payment, the new billing cycle does not start, stripe does.
  const currentPeriodEndDateFirestore =
    subscription.paymentGateway === 'ZENRISE'
      ? subscription.currentPeriodEndDate
      : subscription.currentPeriodStartDate;

  return new Date(
    (currentPeriodEndDateFirestore as unknown as Timestamp)?.seconds * 1000,
  );
}
