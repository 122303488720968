import React from 'react';
import './terms-and-conditions.scss';

export const TermsAndConditions = () => {
  return (
    <section className="terms-and-conditions">
      <div className="wrapper-medium">
        <section className="terms-and-conditions__container">
          <section className="terms-and-conditions__header">
            <div></div>
            <h1 className="h1">Términos y condiciones</h1>
          </section>
          <section className="terms-and-conditions__content">
            <ul className="terms-and-conditions__navigation">
              <li>
                <a href="#cuestiones-previas">Cuestiones previas</a>
              </li>
              <li>
                <a href="#definiciones">Definiciones</a>
              </li>
              <li>
                <a href="#clausulas">Cláusulas</a>
                <ol>
                  <li>
                    <a href="#capacidad">Capacidad</a>
                  </li>
                  <li>
                    <a href="#suscripcion-usuario">Suscripción del Usuario</a>
                  </li>
                  <li>
                    <a href="#datos-personales">
                      Información y Datos Personales suministrados por el Usuario
                    </a>
                  </li>
                  <li>
                    <a href="#acceso-usuario">Acceso a la Cuenta del Usuario</a>
                  </li>
                  <li>
                    <a href="#utilizacion-sitio-web">Utilización del Sitio Web y la Plataforma</a>
                  </li>
                  <li>
                    <a href="#contenido-del-sitio">Contenido del Sitio. Propiedad Intelectual</a>
                  </li>
                  <li>
                    <a href="#derechos-del-usuario">Derechos del Usuario</a>
                  </li>
                  <li>
                    <a href="#responsabilidad-del-usuario">
                      Responsabilidad del Usuario y de Terceros
                    </a>
                  </li>
                  <li>
                    <a href="#indemnidad">Indemnidad</a>
                  </li>
                  <li>
                    <a href="#cesion-o-uso-comercial">Cesión o Uso Comercial NO AUTORIZADO</a>
                  </li>
                  <li>
                    <a href="#suspension">Suspensión y/o Cancelación</a>
                  </li>
                  <li>
                    <a href="#duracion-servicio">Duración y Finalización del Servicio</a>
                  </li>
                  <li>
                    <a href="#modalidad-tarifa">Modalidad de la Tarifa/Canon de los Servicios</a>
                  </li>
                  <li>
                    <a href="#obligaciones-fiscales">Obligaciones Fiscales</a>
                  </li>
                  <li>
                    <a href="#declaraciones-y-garantias">Declaraciones y Garantías</a>
                  </li>
                  <li>
                    <a href="#tolerancia-al-incumplimiento">Tolerancia al incumplimiento</a>
                  </li>
                  <li>
                    <a href="#vinculacion">Vinculación</a>
                  </li>
                  <li>
                    <a href="#canales-de-comunicacion">Canales de Comunicación Disponibles</a>
                  </li>
                  <li>
                    <a href="#responsabilidad">Responsabilidad</a>
                  </li>
                  <li>
                    <a href="#contacto">Contacto</a>
                  </li>
                  <li>
                    <a href="#jurisdiccion">Ley Aplicable y Jurisdicción</a>
                  </li>
                </ol>
              </li>
            </ul>
            <section className="terms-and-conditions__text">
              <section className="terms-and-conditions__section">
                <h4 className="terms-and-conditions__title h4" id="cuestiones-previas">
                  SECCIÓN PRIMERA: CUESTIONES PREVIAS.
                </h4>
                <p>
                  El presente documento establece los términos y condiciones generales (en adelante,
                  los “Términos y Condiciones” o “TYC” indistintamente) aplicables para el uso de
                  los servicios, y/o plataforma, y/o contenidos (en adelante, los “Servicios” y/o
                  “Plataforma” y/o “Contenidos”) que ofrece <strong>REM Softory S.A.S.</strong> (en
                  adelante, “PEDIX”) dentro del sitio web “info.pedix.app” (en adelante, el
                  “Sitio”). Asimismo, los presentes “TYC” son aplicables para el uso del Sitio y/o
                  Plataforma y los Servicios prestados por{' '}
                  <strong>REM Softory S.A.S CUIT 33-71715193-9</strong>, con domicilio en calle
                  Donaciano del Campillo 1312 de la ciudad de Córdoba, Provincia de Córdoba,
                  Argentina. -
                </p>
                <p>
                  <strong>
                    PEDIX le recomienda y solicita a ud. la lectura atenta y completa de los “TYC”
                  </strong>{' '}
                  a los fines de que ud. pueda comprender los mismos.{' '}
                  <strong>
                    En caso de NO comprender, o NO estar de acuerdo con los “TYC”, Política de
                    Privacidad y demás documentos complementarios, ud. deberá abandonar el Sitio y
                    abstenerse de utilizar los Servicios, y/o la Plataforma, y/o los Contenidos
                    ofrecidos.
                  </strong>{' '}
                  La utilización por el Usuario del Sitio, y/o la Plataforma, y/o los Servicios
                  prestados en la misma se entenderán siempre como conocimiento, comprensión y
                  aceptación voluntaria, plena y sin reservas de los “TYC” aquí establecidos. -
                </p>
                <p>
                  PEDIX se reserva el derecho de revisar y/o modificar total o parcialmente los
                  “TYC” y demás documentos que los complementan, en cualquier momento y cuantas
                  veces lo crea conveniente. Dichas modificaciones o revisiones se entenderán
                  notificadas al Usuario con la sola publicación en el sitio de la última versión
                  actualizada, siendo de exclusiva responsabilidad del Usuario consultar y/o
                  informarse periódicamente de las modificaciones y/o nuevos “TYC”. PEDIX podrá
                  notificar al Usuario a la dirección de correo electrónico registrada para utilizar
                  el Sitio y los Servicios. El Usuario acepta que la notificación por parte de PEDIX
                  a dicha dirección de correo electrónico tendrá plena validez como notificación
                  suficiente, y renuncia a cualquier tipo de impugnación respecto de las
                  notificaciones cursadas a tal dirección de correo electrónico. Asimismo, si el
                  Usuario persiste en la utilización de los Servicios y/o del Sitio, se considerará
                  que ha aceptado implícita y completamente estos “TYC”. -
                </p>
                <p>
                  En caso de declararse la nulidad de alguna de las cláusulas de estos “TYC” o
                  documentos complementarios, tal nulidad no afectará a la validez de las restantes
                  cláusulas dispuestas en los “TYC” o documentos complementarios, las cuales
                  mantendrán su plena vigencia y efecto. -
                </p>
                <p>
                  Los presentes “TYC”, junto con la Política de Privacidad, y documentos
                  complementarios constituyen la totalidad del acuerdo entre Ud. y PEDIX respecto
                  del uso del Sitio y la Plataforma. -
                </p>
              </section>
              <section className="terms-and-conditions__section">
                <h4 className="terms-and-conditions__title h4" id="definiciones">
                  SECCIÓN SEGUNDA: DEFINICIONES.
                </h4>
                <p>
                  <strong>Pedix - La Empresa:</strong> Es la empresa que explota comercialmente el
                  Sitio, y que brinda a sus usuarios un servicio de intermediación mediante una
                  plataforma digital. -
                </p>
                <p>
                  <strong>Servicio:</strong> consiste en brindar un sistema autoadministrable para
                  crear catálogos independientes en línea, para dar visibilidad a los productos y/o
                  servicios ofrecidos de los usuarios y agilizar las consultas que tengan de manera
                  directa por WhatsApp, mediante un enlace suministrado por PEDIX. -
                </p>
                <p>
                  <strong>Usuario (Comerciante):</strong> Es toda persona humana mayor de dieciocho
                  (18) años, o persona jurídica que haya completado el proceso de alta como usuario
                  en el sitio de forma exitosa. -
                </p>
                <p>
                  <strong>Cliente del Usuario:</strong> Es toda persona humana mayor a dieciocho
                  (18) años, o persona jurídica, que contrate productos o servicios del usuario a
                  través de la plataforma o link, como destinatario final, en su propio beneficio. -
                </p>
                <p>
                  <strong>Plataforma:</strong> Es el sistema de servicios dispuesto por PEDIX para
                  que el usuario exhiba los productos y servicios catalogados y en existencia
                  (stock) de forma remota. Dicha plataforma se encuentra operativa a través del
                  sitio info.pedix.app. -
                </p>
                <p>
                  <strong>Información en la Plataforma:</strong> Es toda la información orientativa
                  que se provee para facilitar la concreción del servicio, la cual tiene carácter
                  facilitador y no es exhaustiva.-
                </p>
                <p>
                  <strong>Catálogo:</strong> Es la lista de productos o servicios exhibidos a través
                  de la Plataforma, el cual contendrá la información referente a la existencia, o
                  no, de dichos productos y/o servicios en Stock. -
                </p>
                <p>
                  <strong>Producto/s:</strong> Es el bien u objeto, mueble o inmueble, de existencia
                  comercial, catalogado por el Usuario y que éste ofrece a sus clientes a través de
                  la Plataforma. -
                </p>
                <p>
                  <strong>Servicio/s:</strong> Actividad remunerada directa o indirectamente,
                  suministrada, por el Usuario, cualquiera sea su objeto, que tenga por finalidad
                  satisfacer necesidades de sus clientes, en su carácter de destinatarios finales de
                  los mismos. -
                </p>
                <p>
                  <strong>Carrito (Mi orden):</strong> Es la sección de la Plataforma en la que el
                  cliente del usuario acumula los productos y/o servicios a contratar con el
                  Usuario, antes de concretar la transacción/operación. -
                </p>
                <p>
                  <strong>Checkout:</strong> Es la sección de la plataforma en donde el cliente del
                  usuario carga sus datos para finalizar la solicitud de pedido. -
                </p>
                <p>
                  <strong>Precio:</strong> Significa el precio, expresado en moneda de curso legal
                  vigente de los productos o servicios ofrecidos en Catálogo. -
                </p>
              </section>
              <section className="terms-and-conditions__section">
                <h4 className="terms-and-conditions__title h4" id="clausulas">
                  SECCION TERCERA: CLÁUSULAS.
                </h4>
                <ol>
                  <li>
                    <h6 className="h6" id="capacidad">
                      Capacidad
                    </h6>
                    <ol>
                      <li>
                        La persona que desee convertirse en Usuario del Sitio y/o plataforma, y/o
                        utilizar los servicios de PEDIX{' '}
                        <strong>
                          deberá contar con capacidad legal para contratar según la legislación
                          vigente aplicable.
                        </strong>{' '}
                        Aquella persona que no cuente con la capacidad legal requerida, o quien haya
                        sido suspendido o inhabilitado temporal o definitivamente para ejercer el
                        comercio NO podrá ser Usuario ni utilizar los servicios de PEDIX. -
                      </li>
                      <li>
                        En caso de representar una persona jurídica (Empresa o Sociedad), quien
                        efectué la registración deberá estar legitimado para contratar a nombre de
                        la entidad y de obligar a la misma. -
                      </li>
                      <li>
                        Al acceder al Sitio y utilizar los Servicios el Usuario confirma que es
                        mayor de edad y tiene capacidad legal para contratar y/o para actuar
                        personalmente o en nombre de la entidad que representa. -
                      </li>
                      <li>
                        Los clientes del usuario deberán inexorablemente contar con capacidad legal
                        para contratar según la legislación vigente aplicable. -
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h6 className="h6" id="suscripcion-usuario">
                      Suscripción del Usuario.
                    </h6>
                    <ol>
                      <li>
                        Toda persona que desee acceder y/o utilizar el Sitio, así como también el
                        Servicio prestado por PEDIX, deberá suscribirse como Usuario, completando
                        obligatoriamente a tal fin, un formulario de registro de forma exacta y
                        precisa, con datos ciertos, válidos, actuales, y verdaderos sobre su
                        información personal y/o comercial, ("Datos Personales" y/o "Información").
                        <strong>
                          La información proporcionada por el Usuario tendrá el carácter de
                          declaración jurada. -
                        </strong>
                      </li>
                      <li>
                        El proceso de suscripción como Usuario constará de los siguientes pasos:
                        <div className="terms-and-conditions__list-inside">
                          <ul>
                            <li>
                              <span>1) </span>
                              Dentro del sitio <strong>info.pedix.app</strong> deberá hacer click
                              en el botón <strong>“Quiero empezar”.</strong> -
                            </li>
                            <li>
                              <span>2) </span>
                              Inmediatamente podrá visualizar los planes disponibles con la
                              información sobre el precio del plan y las funcionalidades incluidas.
                              -
                            </li>
                            <li>
                              <span>3) </span>
                              Podrá elegir el plan al que quiera suscribirse haciendo click en el
                              botón “Lo quiero”. -
                            </li>
                            <li>
                              <span>4) </span>A continuación, ingresar o registrar los datos
                              personales (nombre; apellido, email; móvil/celular) y hacer click en
                              “siguiente”. -
                            </li>
                            <li>
                              <span>5) </span>
                              Registrar el nombre de la tienda, y seleccionar el/los rubro/s a los
                              que pertenece el negocio y/o servicio, y hacer click en “siguiente”.
                              Es posible que otro Usuario haya utilizado anteriormente el nombre que
                              intenta registrar, por lo que deberá ingresar otro nombre distinto.
                              <strong>
                                PEDIX no es responsable, ni efectua reservas de nombres de marcas o
                                negocios.{' '}
                              </strong>
                              -
                            </li>
                            <li>
                              <span>6) </span>
                              Se asignará automáticamente como nombre de usuario el nombre
                              registrado de la tienda. A su vez deberá cargar una contraseña, y
                              hacer click en “siguiente”.
                            </li>
                            <li>
                              <span>7) </span>
                              Registrar la tarjeta de crédito o débito (número de tarjeta, titular
                              de la tarjeta, DNI del titular, fecha de expiración), y hacer click en
                              “siguiente. -
                            </li>
                            <li>
                              <span>8) </span>
                              Si el procesador de pago acepta la tarjeta, lo llevará a una sección
                              notificándole que se registró correctamente el pago. –
                            </li>
                            <li>
                              <span>9) </span>
                              Finalmente le llegará un correo de bienvenida, al mail registrado,
                              confirmando toda la información previamente cargada o registrada por
                              el Usuario para acceder a su cuenta. -
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li>
                        El Usuario asume el compromiso de actualizar de forma inmediata todos los
                        Datos Personales y/o Información en caso de modificación, alteración o
                        variación de cualquiera de ellos, o bien en caso de resultar necesario. -
                      </li>
                      <li>
                        PEDIX no se responsabiliza por la certeza o veracidad de los Datos
                        Personales provistos por cualquier Usuario, quien garantiza y responde
                        personalmente, en cualquier caso, de la veracidad, exactitud, integridad,
                        vigencia y autenticidad de la Información suministrada por él. -
                      </li>
                      <li>
                        PEDIX se reserva el derecho de solicitar en cualquier momento comprobantes
                        y/o información adicional a fin de cotejar la información brindada por el
                        Usuario en materia de “Datos Personales”. -
                      </li>
                      <li>
                        PEDIX se reserva el derecho de rechazar cualquier solicitud de registro o de
                        cancelar o suspender, de forma temporal y/o definitiva una cuenta, en caso
                        de no poder confirmar los datos, o no resultar ciertos, o cuando se detecten
                        incongruencias o inconsistencias en la información provista por un Usuario;
                        o bien en caso de detectar actividades sospechosas o reñidas con las buenas
                        costumbres y/o la Ley.{' '}
                        <strong>
                          En ningún caso la decisión de PEDIX generará para el Usuario derechos de
                          indemnización o resarcimiento por ningún concepto.
                        </strong>{' '}
                        -
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h6 className="h6" id="datos-personales">
                      Información y Datos Personales suministrados por el Usuario.
                    </h6>
                    <ol>
                      <li>
                        Cuando la persona se registre en el Sitio, se le pedirá que aporte a PEDIX,
                        de forma voluntaria cierta información que incluirá, nombre y apellido,
                        dirección válida de correo electrónico, número de teléfono celular o móvil
                        activo, nombre de la tienda o comercio, rubro/s comercial/es y/o
                        profesional, y datos de su tarjeta de crédito. Toda la Información
                        suministrada de forma voluntaria por el Usuario al registrarse en el Sitio,
                        y cualquier otro dato e información proporcionada posteriormente por éste
                        que sea requerida por PEDIX, se usará conforme a lo estipulado en estos
                        “TYC” y Políticas de Privacidad, los que el Usuario declara conocer y
                        aceptar. -
                      </li>
                      <li>
                        PEDIX utilizará la Información para los siguientes fines:
                        <div className="terms-and-conditions__list-inside">
                          <ul>
                            <li>
                              <span>1)</span> Identificación y autenticación del Usuario.-
                            </li>
                            <li>
                              <span>2)</span> Cobro del fee mensual por el servicio prestado.-
                            </li>
                            <li>
                              <span>3)</span> Administración y gestión comercial.-
                            </li>
                            <li>
                              <span>4)</span> Mejora del servicio.-
                            </li>
                            <li>
                              <span>5)</span> Fines estadísticos.-
                            </li>
                            <li>
                              <span>6)</span> Envío de notificaciones, promociones o publicidad.
                              PEDIX se reserva el derecho de ampliar las finalidades aquí
                              establecidas.-
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li>
                        El Usuario autoriza expresamente a PEDIX a compartir la información personal
                        brindada al momento del registro y verificación de su Cuenta, (incluyendo
                        dirección de e-mail) con instituciones financieras en caso de ser requeridas
                        por procedimientos de prevención de lavado de activo, financiamiento de
                        terrorismo y/o prevención de fraude; con los proveedores de servicios o las
                        empresas que contribuyan a prestar servicio y/o mejorar o facilitar las
                        operaciones a través de info.pedix.app en todas las jurisdicciones donde
                        los Servicios se encuentren disponibles. PEDIX velará porque se cumplan
                        ciertos estándares en la trasmisión de la información, mediante la firma de
                        acuerdos o convenios cuyo objeto sea la privacidad y confidencialidad de los
                        datos personales de nuestros usuarios. -
                      </li>
                      <li>
                        El Usuario autoriza a que la información comercial suministrada por él mismo
                        sea compartida con los clientes del usuario, a los fines de poder prestar el
                        servicio contratado por el Usuario. -
                      </li>
                      <li>
                        El Usuario autoriza a que la información personal y/o comercial suministrada
                        por él mismo, sea compartida ante cualquier requerimiento o procedimiento de
                        carácter administrativo o judicial, efectuado por las autoridades
                        competentes, y amparado en el cumplimiento de lo dispuesto por leyes
                        vigentes. -
                      </li>
                      <li>
                        PEDIX se reserva el derecho de ofrecerle servicios y productos de terceros
                        basados en las preferencias que Usted indicó al momento de registrarse o en
                        cualquier momento posterior; tales ofertas pueden ser efectuadas por PEDIX o
                        por terceros. -
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h6 className="h6" id="acceso-usuario">
                      Acceso a la Cuenta del Usuario.
                    </h6>
                    <ol>
                      <li>
                        El Usuario accederá a su cuenta personal, (en adelante, la “Cuenta”)
                        mediante el ingreso de su nombre de usuario y contraseña. El Usuario debe
                        ser la única persona con conocimiento del nombre de usuario y contraseña
                        para el acceso a su Cuenta, siendo el único responsable de mantener la
                        confidencialidad de sus datos y de su contraseña. -
                      </li>
                      <li>
                        El Usuario será el único responsable por todas las operaciones efectuadas en
                        su Cuenta, ya que el acceso a la misma está restringido al ingreso público
                        mediante el uso de su nombre de usuario y contraseña, datos que serán de
                        único y exclusivo conocimiento del Usuario. -
                      </li>
                      <li>
                        En el caso que el Usuario no recuerde su contraseña deberá contactarse
                        inmediatamente con la empresa a través de un correo electrónico a la{' '}
                        <strong>
                          dirección <a href="mailto:soporte@pedixapp.com">soporte@pedixapp.com</a>
                        </strong>{' '}
                        y seguir los pasos indicados a continuación:
                        <div className="terms-and-conditions__list-inside">
                          <ul>
                            <li>
                              <span>1) </span>
                              El usuario envía un correo electrónico exclusivamente desde el correo
                              con el que se suscribió a{' '}
                              <a href="mailto:soporte@pedixapp.com">soporte@pedixapp.com</a> solicitando
                              la recuperación de su contraseña.-
                            </li>
                            <li>
                              <span>2) </span>
                              Automáticamente se le vuelve a enviar el correo de bienvenida con las
                              credenciales (usuario y contraseña) que el usuario creó en el proceso
                              de registro.-
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li>
                        PEDIX no revelará a terceros el nombre o dirección de correo electrónico del
                        Usuario sin su consentimiento expreso previo, excepto en los casos en que
                        sea necesario para el cumplimiento de lo dispuesto por leyes o
                        procedimientos legales y/o administrativos vigentes, donde tal información
                        sea relevante; o en aquellos casos en que sea estrictamente necesario para
                        la prestación de los servicios a través del Sitio. En esos casos, el
                        receptor de los datos será único y exclusivo responsablemente frente a los
                        daños y perjuicios que pudiera sufrir el Usuario por el traspaso de
                        información. -
                      </li>
                      <li>
                        Se deja expresamente establecido que la Cuenta es personal, única,
                        intransferible, e indelegable; y esa única cuenta solo será asignada a un
                        solo Usuario, estando expresamente prohibido su delegación, préstamo, venta,
                        cesión o transferencia bajo ningún título.{' '}
                        <strong>
                          PEDIX recomienda al Usuario no permitir, autorizar o delegar el uso de su
                          Cuenta a/por terceras personas.
                        </strong>
                        En caso de permitir, autorizar o delegar el uso de su Cuenta a/por terceras
                        personas, el Usuario será exclusivamente responsable de la utilización que
                        se haga de la cuenta por parte de las terceras personas. -
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h6 className="h6" id="utilizacion-sitio-web">
                      Utilización del Sitio Web y la Plataforma.
                    </h6>
                    <ol>
                      <li>
                        El Usuario se compromete a utilizar el Sitio y la Plataforma de conformidad
                        con estos “TYC”, las leyes aplicables a la jurisdicción nacional, así como
                        también conforme las buenas prácticas comerciales, la moral y buenas
                        costumbres. -
                      </li>
                      <li>
                        El Usuario se obliga a abstenerse de utilizar el Sitio y la Plataforma con
                        fines o efectos ilícitos, contrarios a lo establecido en los “TYC”, lesivos
                        de los derechos e intereses de terceros, o que de cualquier forma puedan
                        dañar, inutilizar, sobrecargar o deteriorar el Sitio y/o la Plataforma, o
                        impedir la normal utilización del Sitio y/o la Plataforma por parte del
                        Usuario o terceros. -
                      </li>
                      <li>
                        El Sitio y/o Plataforma podrá ser utilizado solamente con fines comerciales
                        lícitos. PEDIX prohíbe expresa y específicamente cualquier utilización del
                        Sitio y/o la Plataforma para:
                        <div className="terms-and-conditions__list-inside">
                          <ul>
                            <li>
                              <span>1) </span>
                              Anunciar datos biográficos o de otro tipo incompletos, falsos o
                              inexactos propios o de terceros.-
                            </li>
                            <li>
                              <span>2) </span>Usar cualquier mecanismo para impedir o intentar
                              impedir el adecuado funcionamiento de este Sitio o cualquier actividad
                              que se esté realizando en este Sitio.-
                            </li>
                            <li>
                              <span>3) </span>
                              Intentar descifrar, descompilar u obtener el código fuente de
                              cualquier programa de software de este Sitio.-
                            </li>
                            <li>
                              <span>4) </span>Utilizar el Sitio y/o la Plataforma para transmitir,
                              distribuir, publicitar, exponer productos o material ya sea violando
                              la normativa vigente, o de forma que se infrinjan derechos de terceros
                              o se viole la confidencialidad, honor, privacidad, imagen u otros
                              derechos personales de otras terceras personas.-
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li>
                        El Usuario tiene prohibido violar o intentar violar la seguridad del Sitio
                        y/o la Plataforma, incluyendo pero no limitándose a:
                        <div className="terms-and-conditions__list-inside">
                          <ul>
                            <li>
                              <span>1) </span>
                              El acceso a datos que no estén destinados a tal usuario o entrar en un
                              servidor o cuenta cuyo acceso no está autorizada al Usuario.-
                            </li>
                            <li>
                              <span>2) </span>Evaluar o probar la vulnerabilidad de un sistema o
                              red, o violar las medidas de seguridad o identificación.-
                            </li>
                            <li>
                              <span>3) </span>Intentar impedir el Servicio a cualquier Usuario,
                              anfitrión o red, incluyendo, pero sin limitación, mediante el envío de
                              virus al Sitio, o mediante saturación o ataques de denegación de
                              Servicio.-
                            </li>
                            <li>
                              <span>4) </span>Enviar correos electrónicos no solicitados, incluyendo
                              promociones y/o publicidad de productos o servicios ya sea a otros
                              Usuarios, o clientes de otros Usuarios o a cualquier otra persona.-
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li>
                        Las violaciones de la seguridad del Sitio pueden constituir contravenciones
                        y/o delitos penales; pudiendo derivar en responsabilidades penales y
                        civiles. PEDIX investigará los casos de violaciones o intento de violación a
                        la seguridad del Sitio y/o plataforma, pudiendo dirigirse a la autoridad
                        administrativa, policial o judicial competente a los efectos de perseguir al
                        Usuario involucrado, o terceros participes en tales violaciones. -
                      </li>
                      <li>
                        PEDIX se reserva el derecho de suspender o dar de baja a cualquier Usuario
                        que, a exclusivo criterio de la Empresa, no cumpla con los estándares
                        definidos en estos “TYC” o con las políticas vigentes de PEDIX, sin que ello
                        genere derecho a resarcimiento alguno. -
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h6 className="h6" id="contenido-del-sitio">
                      Contenido del Sitio. Propiedad Intelectual.
                    </h6>
                    <ol>
                      <li>
                        Todos el contenido de este Sitio, tales como, textos, información, gráficos,
                        imágenes, insignias, enseñas, logotipos, isotipos, marcas, nombres
                        comerciales, nombres de fantasía, programas, bases de datos, diseños,
                        arquitectura funcional, software, nombres de dominio, “know-how” y cualquier
                        otro material (en adelante, el “Contenido”) están protegidos por la
                        normativa legal vigente, entre ellas pero sin limitar, a las leyes sobre
                        derechos de autor, patentes, marcas, modelos de utilidad, diseños
                        industriales y nombres de dominio. -
                      </li>
                      <li>
                        Todo el Contenido es propiedad de PEDIX. El uso, adaptación, reproducción
                        y/o comercialización no autorizada del Contenido puede encontrarse penado
                        por la normativa legal vigente. -
                      </li>
                      <li>
                        El Usuario se compromete a no utilizar, bajo ninguna forma, “El Contenido” y
                        todos los demás bienes amparados por derechos de propiedad intelectual e
                        industrial (de cualquier tipo y naturaleza a nivel mundial
                        independientemente de su designación) de PEDIX sin previa autorización por
                        escrito. -
                      </li>
                      <li>
                        El Usuario reconoce el derecho, título e interés de PEDIX en las marcas
                        registradas o no, textos, información, gráficos, imágenes, insignias,
                        enseñas, logotipos, isotipos, marcas, nombres comerciales, nombres de
                        fantasía, programas, bases de datos, diseños, arquitectura funcional,
                        software, nombres de dominio, “know-how”, y cualquier otro material no
                        registrados que identifiquen y distingan a PEDIX, y acepta no participar en
                        actividades o cometer acto alguno que, directa o indirectamente, pueda
                        disputar o poner en riesgo dicho derecho, título o interés de PEDIX.
                        Asimismo, el Usuario expresamente se compromete a no solicitar ni adquirir
                        ante las autoridades competentes, bien como marca de producto, servicio,
                        denominación, nombre de dominio, razón social o nombre comercial; marcas
                        iguales, parecidas o confundibles a las marcas registradas u otros signos no
                        registrados que identifiquen y distingan a PEDIX, ni reclamar derechos,
                        títulos o intereses en o sobre las marcas de PEDIX, renunciando expresamente
                        por medio del presente a todas sus pretensiones con respecto al Contenido
                        enunciado en los “TYC”. -
                      </li>
                      <li>
                        Los contenidos de las pantallas relativas al Sitio como así también los
                        programas, bases de datos, redes y archivos que permiten al Usuario acceder
                        y usar su Cuenta, son de propiedad exclusiva de PEDIX y están protegidas por
                        las leyes y los tratados internacionales de derecho de autor, marcas,
                        patentes, modelos y diseños industriales.-
                      </li>
                      <li>
                        El Usuario no copiará ni adaptará el código de programación desarrollado
                        por, o por cuenta de PEDIX para generar y operar sus páginas, el cual se
                        encuentra protegido por la legislación aplicable y vigente en cada
                        jurisdicción. -
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h6 className="h6" id="derechos-del-usuario">
                      Derechos del Usuario
                    </h6>
                    <p>
                      Sin perjuicio de todos los derechos establecidos en los presentes “TYC”, así
                      como también por la legislación vigente aplicable, el Usuario tiene derecho a:
                    </p>
                    <ol>
                      <li>
                        En cualquier momento, tener acceso en el Sitio a la versión actualizada de
                        los “TYC” y Políticas de Privacidad vigentes al momento de dicha consulta. -
                      </li>
                      <li>
                        Ser informado por PEDIX, sin costo alguno, de cualquier incumplimiento a los
                        “TYC” que cometa en su calidad de Usuario. Asimismo, deberá ser informado en
                        caso de que se le aplique una sanción y/o suspensión temporal o definitiva,
                        indicando el motivo y otorgándole derecho a ejercer su descargo. -
                      </li>
                      <li>
                        Solicitar a PEDIX, sin costo alguno, la actualización de su información
                        personal; o bien la modificación o supresión de información inexacta o
                        incorrecta de su perfil de usuario. -
                      </li>
                      <li>
                        Solicitar a PEDIX, sin costo alguno, la suspensión temporal o baja
                        definitiva de la Cuenta de Usuario en casos de uso no autorizado. -
                      </li>
                      <li>
                        Solicitar a PEDIX, sin costo alguno, toda aquella información que PEDIX
                        pueda tener respecto de la violación o intento de violación a la Cuenta de
                        Usuario. -
                      </li>
                      <li>
                        Obtener, sin costo alguno, la ayuda del soporte técnico disponible en el
                        Sitio a los fines de solucionar problemas técnicos del Sitio, o bien evacuar
                        dudas sobre el funcionamiento del Sitio o la prestación del Servicio. -
                      </li>
                      <li>
                        Decidir voluntariamente sobre la conveniencia de su permanencia en el Sitio
                        en carácter de Usuario, así como la utilización del Servicio. -
                      </li>
                      <li>
                        Decidir voluntariamente la oportunidad para solicitar la baja como Usuario
                        del Sitio y por ende del Servicio prestado por PEDIX. -
                      </li>
                      <li>
                        Solicitar la baja, sin costo alguno, de su cuenta de Usuario del Sitio y por
                        ende de Servicio prestado por PEDIX. -
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h6 className="h6" id="responsabilidad-del-usuario">
                      Responsabilidad del Usuario y de Terceros.
                    </h6>
                    <ol>
                      <li>
                        El Usuario reconoce y acepta que utiliza el Sitio y la Plataforma bajo su
                        única y exclusiva responsabilidad. Cada Usuario conoce y acepta ser el
                        exclusivo responsable de su accionar dentro del Sitio y fuera de este por
                        toda aquella actividad vinculada con el Sitio y/o el Servicio proporcionado
                        por PEDIX. El Usuario conoce y acepta que al realizar operaciones a través
                        del Sitio y/o la Plataforma lo hace bajo su propio riesgo y responsabilidad.
                        En ningún caso PEDIX será responsable por lucro cesante, o por cualquier
                        otro daño y/o perjuicio que pueda sufrir el Usuario, debido a las
                        operaciones que se concreten a través del servicio. PEDIX no será
                        responsable por la interacción del Usuario basada en la confianza depositada
                        en el sistema, Plataforma o en el Sitio. -
                      </li>
                      <li>
                        Al aceptar estos “TYC”, el Usuario exime a PEDIX, a las empresas vinculadas
                        y asociadas de cualquier forma, a sus directores, miembros, representantes
                        y/o empleados de cualquier responsabilidad derivada de acciones y/o demandas
                        y/o indemnizaciones por daños de cualquier clase y/o naturaleza que tuviera
                        origen o conexión en un enfrentamiento o controversia entre ellos. -
                      </li>
                      <li>
                        El Sitio puede contener enlaces a apps y/o sitios web o redes sociales de
                        propiedad de terceros lo cual no implica que sean propiedad u operados por
                        PEDIX, ni que tengan relación alguna con éste. En virtud de que PEDIX no
                        tiene control sobre tales apps y/o sitios y/o redes sociales, no será
                        responsable por los contenidos, y/o materiales, y/o acciones y/o servicios
                        prestados por los mismos, ni por daños y/o pérdidas ocasionadas al Usuario
                        por su utilización, sean causadas directa o indirectamente. La presencia de
                        enlaces a otros sitios web no implica una sociedad, relación, aprobación ni
                        respaldo de PEDIX, ni de sus sociedades vinculadas o controladas con los
                        titulares de dichos sitios o contenidos. –
                      </li>
                      <li>
                        PEDIX no asegura o garantiza el correcto funcionamiento, o el funcionamiento
                        continuado e ininterrumpido de otros sitios web y/o redes sociales (como por
                        ejemplo, y sin limitarse: Facebook, Instagram, etc.) o de aplicaciones (como
                        por ejemplo WhatsApp) que el Usuario utilice a los fines de publicitar sus
                        productos y/o servicios, o bien establecer contactos comerciales con sus
                        clientes, por tratarse dichos sitios o aplicaciones de propiedad de empresas
                        ajenas a PEDIX. En este sentido el Usuario reconoce que PEDIX no tendrá
                        ninguna responsabilidad por la falta de funcionamiento y/o funcionamiento
                        interrumpido o intermitente y/o mal funcionamiento de ninguna red social,
                        sitio web o aplicación, renunciando expresamente a presentar cualquier tipo
                        de planteo, queja o reclamo ya sea administrativo y/o judicial en contra de
                        PEDIX por el lucro cesante, o por cualquier otro daño y/o perjuicio que
                        pueda sufrir el Usuario como consecuencia de la falta de funcionamiento,
                        funcionamiento interrumpido o intermitente, o mal funcionamiento de
                        cualquier red social, sitio web o aplicación ajeno a PEDIX. -
                      </li>
                      <li>
                        Al aceptar los presentes “TYC”, el Usuario acepta y reconoce que el comercio
                        de bienes, productos y/o servicios, reales o virtuales implica un riesgo
                        significativo debido a pérdidas que se pueden producir como resultado de las
                        fluctuaciones propias de un mercado, así como también de otras vicisitudes.
                        Teniendo en cuenta estas consideraciones, el Usuario acepta, reconoce y
                        asume que pueden existir riesgos adicionales que no han sido expresamente
                        previstos en estos “TYC”, ajenos a PEDIX, y libera de cualquier clase de
                        responsabilidad a PEDIX por lucro cesante, daños o pérdidas sufridas como
                        consecuencia directa e indirecta de las características propias de los
                        mercados u otras vicisitudes. -
                      </li>
                      <li>
                        tento al estado de la técnica y a la estructura y funcionamiento de las
                        redes, el Sitio no puede confirmar que cada Usuario es quien dice ser. -
                      </li>
                      <li>
                        PEDIX no garantiza la disponibilidad y continuidad del funcionamiento del
                        Sitio, de su Plataforma o del Servicio. El Servicio y contenidos en general
                        no se encuentran disponibles para todas las áreas geográficas. Asimismo,
                        PEDIX no garantiza la utilidad del Sitio, la Plataforma y de los Servicios
                        para la realización de ninguna actividad en particular, ni su infalibilidad
                        y, en particular, aunque no de modo exclusivo, que el Usuario pueda
                        efectivamente utilizar el Sitio. -
                      </li>
                      <li>
                        PEDIX no garantiza la privacidad y seguridad de la utilización del Sitio y,
                        en particular, no garantiza que terceros no autorizados no puedan tener
                        conocimiento de la clase, condiciones, características y circunstancias del
                        uso que el Usuario hace del Sitio. -
                      </li>
                      <li>
                        PEDIX no garantiza que el Sitio funcionará libre de errores. El Sitio y el
                        Servicio se suministra tal como está disponible actualmente al Usuario, sin
                        garantías de ninguna clase. PEDIX no asume garantías sobre la exactitud,
                        veracidad, exhaustividad o actualización de los contenidos, los servicios,
                        el software, los textos, los gráficos y los vínculos disponibles en el
                        Sitio. -
                      </li>
                      <li>
                        En ningún caso PEDIX será responsable de cualquier daño incluyendo, pero sin
                        limitación, daños directos y/o indirectos, lucro cesante o pérdida de chance
                        que resulten del uso o de la imposibilidad de uso del Sitio. -
                      </li>
                      <li>
                        El Usuario exime a PEDIX de toda responsabilidad por los daños y perjuicios
                        de cualquier naturaleza que pudieran deberse al accionar de terceros no
                        autorizados respecto de los Datos Personales del Usuario, así como de los
                        Servicios ofrecidos en el Sitio. -
                      </li>
                      <li>
                        PEDIX no se responsabiliza por cualquier daño, perjuicio o pérdida que sufra
                        un Usuario causados por fallas en el sistema, en el servidor o en Internet,
                        tampoco será responsable por cualquier virus que pudiera infectar el equipo
                        del Usuario como consecuencia del acceso, uso o examen del Sitio o a raíz de
                        cualquier transferencia de datos, archivos, imágenes, textos, o audio
                        contenidos en el mismo. El Usuario no podrá imputarle responsabilidad alguna
                        ni exigirle indemnizaciones, en virtud de perjuicios resultantes de
                        dificultades técnicas o fallas en los sistemas o en Internet. PEDIX no
                        garantiza el acceso y uso continuado o ininterrumpido de su Sitio. El
                        sistema puede eventualmente no estar disponible debido a dificultades
                        técnicas o fallas de la red, o por cualquier otra circunstancia ajena a
                        PEDIX; en tales casos se procurará restablecerlo con la mayor celeridad
                        posible sin que por ello pueda imputársele algún tipo de responsabilidad a
                        PEDIX. -
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h6 className="h6" id="indemnidad">
                      Indemnidad.
                    </h6>
                    <p>
                      El Usuario se obliga a defender, mantener indemne, y en su caso indemnizar a
                      PEDIX, sus directivos, empleados, representantes, y toda aquella persona o
                      entidad vinculada con la Empresa, de y contra cualquier reclamo, cargo o
                      acción, incluyendo pero no limitándose a cualquier acción ya sea
                      extrajudicial, administrativa, y/o judicial, que resulten de cualquier acto o
                      hecho licito o ilícito imputable al Usuario o a un tercero que utilice la
                      cuenta del Usuario; o por el uso indebido que el Usuario haga del Sitio o de
                      la Plataforma; o bien de la infracción por parte del Usuario de los “TYC”;
                      debiendo hacerse cargo el Usuario en todos los casos, de todos los gastos
                      legales y/o de cualquier otra naturaleza en los que PEDIX, las sociedades
                      vinculadas, sus directivos, empleados, representantes, y toda aquella persona
                      vinculada con la Empresa, pudieran incurrir por dicha situación. A tal fin,
                      PEDIX le notificará puntualmente de cualquier demanda, reclamo y/o acción y/o
                      proceso. -
                    </p>
                  </li>
                  <li>
                    <h6 className="h6" id="cesion-o-uso-comercial">
                      Cesión o Uso Comercial NO AUTORIZADO.
                    </h6>
                    <ol>
                      <li>
                        El Usuario expresamente se compromete a no ceder, bajo ningún título, sus
                        derechos u obligaciones bajo estos “TYC”. Asimismo, se obliga a no realizar
                        ningún uso comercial no autorizado del Sitio y/o su Plataforma y/o los
                        Servicios. -
                      </li>
                      <li>
                        El Usuario se compromete a utilizar el Sitio, la Plataforma y los Servicios
                        diligentemente y de conformidad con los “TYC”, así como también conforme la
                        ley aplicable y vigente. -
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h6 className="h6" id="suspension">
                      Suspensión y/o Cancelación.
                    </h6>
                    <ol>
                      <li>
                        PEDIX se reserva el derecho y la facultad, de emitir advertencias,
                        suspensiones temporales y cancelaciones permanentes (baja) del Usuario
                        registrado por infracciones a estos “TYC”, reglamentos, y/o legislación
                        vigente, así como también por falta de pago del Servicio prestado. -
                      </li>
                      <li>
                        Sin perjuicio de lo indicado en el punto anterior, PEDIX se reserva el
                        derecho y la facultad, a su exclusivo criterio, de accionar legalmente por
                        los daños y perjuicios que el Usuario pueda ocasionar como resultado del
                        incumplimiento de cualquiera de las cláusulas contenidas en estos “TYC”,
                        reglamentos y/o legislación vigente. -
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h6 className="h6" id="duracion-servicio">
                      Duración y Finalización del Servicio.
                    </h6>
                    <p>
                      El servicio ofrecido por PEDIX tendrá una periodicidad continua mensual y
                      condicionada siempre al pago del canon correspondiente. En caso de no abonar
                      el canon el servicio finalizará de forma automática e inmediata al concluir el
                      período en curso. –
                    </p>
                  </li>
                  <li>
                    <h6 className="h6" id="modalidad-tarifa">
                      Modalidad de la Tarifa/Canon de los Servicios.
                    </h6>
                    <p>
                      La tarifa establecida por PEDIX para la prestación del servicio, es de
                      modalidad “tarifa plana”, lo que implica que no habrá variaciones por la
                      cantidad de uso que el Usuario haga de su cuenta, y sin limitación de
                      productos y categorías o subcategorías que el usuario pueda cargar en su
                      catálogo o de las funcionalidades acordes al plan contratado. La modalidad de
                      “Tarifa Plana” no implica que el precio del servicio se mantendrá inalterable,
                      o que no pueda sufrir variaciones acordes al proceso inflacionario del país. -
                    </p>
                  </li>
                  <li>
                    <h6 className="h6" id="obligaciones-fiscales">
                      Obligaciones Fiscales.
                    </h6>
                    <ol>
                      <li>
                        El Usuario asume expresamente que será responsable por todas las
                        obligaciones y cargas impositivas que correspondan por sus operaciones, sin
                        que pueda imputársele a PEDIX ningún tipo de responsabilidad derivada de las
                        mismas. -
                      </li>
                      <li>
                        PEDIX no responde por el efectivo cumplimiento de las obligaciones fiscales
                        o impositivas establecidas por las leyes aplicables y/o cualquier otra
                        obligación que surja por las actividades del Usuario. Asimismo, tampoco se
                        responsabiliza por el incumplimiento por parte del Usuario de obligaciones
                        fiscales o impositivas establecidas por las leyes aplicables y/o cualquier
                        otra obligación que surja por las actividades del Usuario. -
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h6 className="h6" id="declaraciones-y-garantias">
                      Declaraciones y Garantías.
                    </h6>
                    <ol>
                      <li>
                        El Usuario comprende, reconoce y acepta que PEDIX:
                        <div className="terms-and-conditions__list-inside">
                          <ul>
                            <li>
                              <span>1) </span>
                              No garantiza la utilidad de los Servicios para finalidades
                              específicas.-
                            </li>
                            <li>
                              <span>2) </span>
                              No será responsable por las relaciones que como consecuencia del uso
                              del Servicios puedan entablarse entre los Usuarios del Sitio y sus
                              clientes.-
                            </li>
                            <li>
                              <span>3) </span>
                              No será responsable por la veracidad de los datos y/o información, y/o
                              la licitud de los contenidos que los Usuarios ingresen al Sitio.-
                            </li>
                            <li>
                              <span>4) </span>
                              No tiene la obligación, pero si la facultad, de verificar la identidad
                              de los usuarios del Sitio, ni la veracidad, vigencia, y/o autenticidad
                              de los datos que los usuarios proporcionen sobre sí mismos.-
                            </li>
                            <li>
                              <span>5) </span>
                              No garantiza ningún mercado, por lo que el Usuario excluye toda
                              responsabilidad de PEDIX por los daños y perjuicios de toda naturaleza
                              que pudiera sufrir como consecuencia de la ausencia de tal mercado.-
                            </li>
                            <li>
                              <span>6) </span>
                              No garantiza el correcto funcionamiento de sitios web, redes sociales
                              o aplicaciones ajenos a PEDIX, por lo que el Usuario excluye toda
                              responsabilidad de PEDIX por los daños y perjuicios de toda naturaleza
                              que pudiera sufrir como consecuencia de fallas en el funcionamiento de
                              dichos sitios, redes sociales o Apps. -
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li>
                        El Usuario declara y garantiza que:
                        <div className="terms-and-conditions__list-in">
                          <ul>
                            <li>
                              <span>1) </span>
                              El uso del Sitio y del Servicio se lleva a cabo bajo su única y
                              exclusiva responsabilidad y riesgo comercial.-
                            </li>
                            <li>
                              <span>2) </span>
                              Utilizará el Sitio y el Servicio en un todo momento de acuerdo estos
                              “TYC”, a la normativa vigente y demás leyes, decretos, resoluciones,
                              disposiciones y decisiones gubernamentales que en el futuro se dicten
                              y resulten aplicables.-
                            </li>
                            <li>
                              <span>3) </span>
                              Cuenta con plenas facultades para aceptar estos “TYC” y cumplir con
                              todas las obligaciones emergentes de su vinculación con PEDIX.-
                            </li>
                            <li>
                              <span>4) </span>
                              Ningún integrante del personal del Usuario actuará ni realizará
                              declaración alguna ni actuará de modo tal que razonablemente pueda dar
                              lugar a un daño para PEDIX, inclusive un perjuicio a su reputación.-
                            </li>
                            <li>
                              <span>5) </span>
                              Indemnizará a PEDIX por las pérdidas sufridas como resultado de
                              cualquier violación a cualquier garantía estipulada en la presente
                              cláusula.-
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h6 className="h6" id="tolerancia-al-incumplimiento">
                      Tolerancia al incumplimiento.
                    </h6>
                    <p>
                      La tolerancia de PEDIX respecto del incumplimiento del Usuario a cualquier
                      disposición contenida en los presentes “TYC” no podrá entenderse como
                      conformidad y aceptación respecto el accionar del Usuario o como renuncia a
                      ejercer sus derechos para exigir su cumplimiento. -
                    </p>
                  </li>
                  <li>
                    <h6 className="h6" id="vinculacion">
                      Vinculación.
                    </h6>
                    <ol>
                      <li>
                        El Usuario comprende, reconoce y acepta que la aceptación de estos “TYC” no
                        crea ningún contrato de sociedad, de mandato, distribución, agencia,
                        concesión, franquicia, ni mucho menos una relación laboral entre PEDIX y el
                        Usuario. -
                      </li>
                      <li>
                        El Usuario comprende, reconoce y acepta que el único vínculo existente entre
                        éste y PEDIX es la prestación de un servicio a título oneroso (pago), por
                        parte de PEDIX al usuario. -
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h6 className="h6" id="canales-de-comunicacion">
                      Canales de Comunicación Disponibles.
                    </h6>
                    <ol>
                      <li>
                        En la medida de su disponibilidad, el Usuario deberá utilizar los canales de
                        comunicación E-Mail y WhatsApp, entre otros- (en adelante, los “Canales”) de
                        forma responsable y correcta, conforme se establece en los presentes “TYC”.
                        -
                      </li>
                      <li>
                        El contenido de cada mensaje enviado por el Usuario a través de los Canales
                        es de única y exclusiva responsabilidad del Usuario. PEDIX no garantiza la
                        veracidad de los datos personales y/o contenidos de cada mensaje efectuados
                        y/o publicados en los Canales por el Usuario. El Usuario acepta
                        voluntariamente que el acceso a y/o el uso de los Canales tiene lugar, en
                        todo caso, bajo su exclusiva y única responsabilidad. -
                      </li>
                      <li>
                        PEDIX se reserva el derecho a suspender temporal o definitivamente de los
                        Canales y/o de los Servicios sin previo aviso, a quien no respete estos
                        “TYC” o realice actos que atenten contra el normal funcionamiento de los
                        Servicios y/o de los Canales y/o del Sitio. -
                      </li>
                      <li>
                        El Usuario reconoce y acepta que las siguientes conductas se encuentran
                        terminantemente prohibidas tanto para la comunicación como para el uso del
                        sitio:
                        <div className="terms-and-conditions__list-inside">
                          <ul>
                            <li>
                              <span>1) </span>
                              Utilizar lenguaje vulgar /obsceno, discriminatorio y/u ofensivo.-
                            </li>
                            <li>
                              <span>2) </span>
                              Todo tipo de ataque personal, acoso, amenazas, insultos contra otros
                              Usuarios y/o terceros, mediante.-
                            </li>
                            <li>
                              <span>3) </span>
                              Todo acto contrario a las leyes, la moral y las buenas costumbres.-
                            </li>
                            <li>
                              <span>4) </span>
                              Publicar mensajes, imágenes e hipervínculos agraviantes, difamatorios,
                              calumniosos, injuriosos, falsos, discriminatorios, pornográficos, de
                              contenido violento, insultantes, amenazantes, incitantes a conductas
                              ilícitas o peligrosas para la salud, y/o que vulneren de cualquier
                              forma la privacidad de cualquier tercero como así también la violación
                              directa o indirecta de los derechos de propiedad intelectual de PEDIX
                              y/o de cualquier tercero, incluyendo clientes de PEDIX.-
                            </li>
                            <li>
                              <span>5) </span>
                              Publicar mensajes que puedan herir y/o afectar la sensibilidad de
                              cualquier Usuario y/o de cualquier tercero.-
                            </li>
                            <li>
                              <span>6) </span>
                              Promocionar, comercializar, vender, publicar y/u ofrecer cualquier
                              clase de productos, servicios y/o actividades por intermedio de o a
                              través de la utilización de los Canales, excepto aquellas expresamente
                              permitidas por PEDIX.-
                            </li>
                            <li>
                              <span>7) </span>
                              La venta, locación o cesión, ya sea a título oneroso o gratuito, del
                              espacio de comunicación de los Canales.-
                            </li>
                            <li>
                              <span>8) </span>
                              Publicar mensajes que de cualquier forma contengan publicidad no
                              permitida por PEDIX o estipulada en el Servicio.-
                            </li>
                            <li>
                              <span>9) </span>
                              El uso o envío de virus informáticos, malware, spyware, ransomware y/o
                              la realización de todo acto que cause o pudiera causar daños o
                              perjuicios al normal funcionamiento de los Servicios y/o los Canales,
                              o de los equipos informáticos o software de PEDIX y/o de cualquier
                              tercero, incluyendo clientes de PEDIX.-
                            </li>
                            <li>
                              <span>10) </span>
                              Todo acto dirigido a enmascarar y/o falsificar o disimular direcciones
                              IP, correos electrónicos y/o cualquier otro medio técnico de
                              identificación del Usuario o sus equipos informáticos.-
                            </li>
                            <li>
                              <span>11) </span>
                              Todo acto que viole la privacidad de otro Usuario, o que viole
                              cualquiera de sus derechos.-
                            </li>
                            <li>
                              <span>12) </span>
                              La publicación de datos personales o imágenes sin el consentimiento
                              expreso del titular de los mismos.-
                            </li>
                            <li>
                              <span>13) </span>
                              La transmisión o divulgación de material que viole la legislación en
                              vigor en el país y/o que pudiera herir la sensibilidad de cualquier
                              Usuario y/o de cualquier tercero.-
                            </li>
                            <li>
                              <span>14) </span>
                              La publicación de cualquier tipo de contenido en violación de derechos
                              de terceros, incluyendo sin limitación los derechos de propiedad
                              intelectual y/o industrial.-
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li>
                        PEDIX no tiene obligación de controlar la utilización que el Usuario haga de
                        los Canales. No obstante ello, PEDIX se reserva el derecho de no publicar o
                        remover luego de ser publicados todos aquellos contenidos y/o mensajes
                        propuestos y/o publicados por el Usuario que, a exclusivo criterio de PEDIX,
                        no respondan estrictamente a las disposiciones contenidas en estos “TYC” y/o
                        resulten impropios y/o inadecuados a las características, finalidad y/o
                        calidad de los Servicios. -
                      </li>
                      <li>
                        PEDIX no garantiza la disponibilidad y continuidad del funcionamiento de los
                        Canales ni es responsable de la destrucción, alteración o eliminación del
                        contenido o información que cada Usuario incluya en sus mensajes. -
                      </li>
                      <li>
                        Cada Usuario es único y exclusivo responsable de sus manifestaciones,
                        dichos, opiniones y todo acto que realice a través de los Canales. -
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h6 className="h6" id="responsabilidad">
                      Responsabilidad
                    </h6>
                    <ol>
                      <li>
                        El servicio prestado por PEDIX implica solamente la puesta a disposición de
                        los Usuarios un espacio virtual que mediante un sistema autoadministrable de
                        gestión de pedidos online para realizar la venta de sus productos y/o
                        servicios s de manera directa por WhatsApp. En ese contexto, PEDIX NO es el
                        propietario de los productos y/o servicios ofrecidos por los Usuarios y por
                        ende no será responsable respecto de la existencia, calidad, cantidad,
                        disponibilidad, estado, integridad o legitimidad de los bienes ofrecidos,
                        adquiridos o enajenados por los Usuarios. Cada Usuario reconoce y acepta ser
                        el exclusivo responsable por los artículos y/o servicios que publica para su
                        comercialización. -
                      </li>
                      <li>
                        PEDIX NO interviene en la conformación de las condiciones estipuladas por el
                        Usuario respecto la compra-venta de los productos o prestación de los
                        servicios ofrecidos mediante su plataforma; NO fija los precios de los
                        productos y/o servicios ofrecidos; NO participa en el perfeccionamiento de
                        las operaciones realizadas entre los Usuarios y sus clientes. Cada Usuario
                        reconoce y acepta ser el exclusivo responsable por la conformación de las
                        condiciones estipuladas para la venta de sus productos o prestación de sus
                        servicios. -
                      </li>
                      <li>
                        PEDIX NO asegura la capacidad para contratar de los Usuarios o de la
                        veracidad de los Datos Personales por ellos ingresados. Cada Usuario
                        reconoce y acepta ser el exclusivo responsable por la veracidad de sus Datos
                        Personales, así como por su declaración de capacidad a los fines de
                        contratar. -
                      </li>
                      <li>
                        PEDIX, al proveer solamente un servicio de conexión, o un canal de
                        comunicación entre el Usuario y su cliente, no será responsable por el
                        efectivo cumplimiento de las obligaciones asumidas por los Usuarios en el
                        perfeccionamiento de la operación. El Usuario conoce y acepta que al
                        realizar operaciones con sus clientes o terceros lo hace bajo su propio
                        riesgo. En ningún caso PEDIX será responsable por lucro cesante, o por
                        cualquier otro daño y/o perjuicio que haya podido sufrir el Usuario, debido
                        a las operaciones realizadas o no realizadas por productos publicados a
                        través del Sitio o su Plataforma. -
                      </li>
                      <li>
                        PEDIX NO tiene control alguno sobre la calidad, seguridad o legalidad de los
                        productos anunciados, ni por la capacidad de los Usuarios para venderlos
                        productos publicados, motivo por el cual no responde por la veracidad o
                        exactitud de los anuncios, ni puede asegurar que un Usuario completará una
                        operación. –
                      </li>
                    </ol>
                  </li>
                  <li>
                    <h6 className="h6" id="contacto">
                      Contacto
                    </h6>
                    <p>
                      Si tiene preguntas sobre los presentes TYC, o por cualquier consulta
                      relacionada con el servicio brindado por PEDIX, por favor contáctenos a los
                      siguientes correos electrónicos:{' '}
                      <a href="mailto:soporte@pedixapp.com">soporte@pedixapp.com</a> . -
                    </p>
                  </li>
                  <li>
                    <h6 className="h6" id="jurisdiccion">
                      Ley Aplicable y Jurisdicción
                    </h6>
                    <p>
                      En caso de conflicto derivado del incumplimiento o interpretación y aplicación
                      de estos “TYC”, será de aplicación la normativa legal vigente en la República
                      Argentina. -
                    </p>
                  </li>
                </ol>
              </section>
            </section>
          </section>
        </section>
      </div>
    </section>
  );
};
