import React from 'react';
import './spinner.scss';

export const Spinner = () => {
  return (
    <section className="spinner-wrapper">
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </section>
  );
};
