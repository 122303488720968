export const subscriptionWizardFiles = [
  { type: 'script', src: `${process.env.GATSBY_SUBSCRIPTION_WIZARD_URL}/runtime.js` },
  { type: 'script', src: `${process.env.GATSBY_SUBSCRIPTION_WIZARD_URL}/polyfills.js` },
  { type: 'script', src: `${process.env.GATSBY_SUBSCRIPTION_WIZARD_URL}/vendor.js` },
  { type: 'script', src: `${process.env.GATSBY_SUBSCRIPTION_WIZARD_URL}/main.js` },
  {
    type: 'script',
    src: `${process.env.GATSBY_SUBSCRIPTION_WIZARD_URL}/apps_subscription-wizard_src_app_features_registration_registration_module_ts.js`,
  },
  { type: 'style', src: `${process.env.GATSBY_SUBSCRIPTION_WIZARD_URL}/styles.css` },
];

export const ANALYTICS_EVENTS = {
  HOMEPAGE_MENU_CLICKED: 'homepage_menu_clicked',
  HOMEPAGE_DEMO_VIEWED: 'homepage_demo_viewed',
  HOMEPAGE_PRICING_VIEWED: 'homepage_pricing_viewed',
  HOMEPAGE_SUBSCRIPTION_STARTED: 'add_to_cart_landing',
  HOMEPAGE_SUBSIDIARY_CLICKED: 'homepage_subsidiary_clicked',
  HOMEPAGE_FAQS_CLICKED: 'homepage_faqs_clicked',
  HOMEPAGE_FOOTER_CLICKED: 'homepage_footer_clicked',
  HOMEPAGE_BILLING_TYPE_CHANGED: 'homepage_billingtype_changed'
};

