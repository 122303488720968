import { graphql } from 'gatsby';

import React from 'react';

import NotFound from '../sections/NotFound';

const NotFoundPageNew = () => {
  return <NotFound></NotFound>;
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;


export default NotFoundPageNew;
