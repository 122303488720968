import React, { useContext, useEffect, useRef, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import './header.scss';
import Button from '../Button';
import { menuOptions } from '../../utils/menu';
import { Link } from 'gatsby-plugin-react-i18next';
import GlobalContext from '../../context/GlobalContext';
import pedixLogo from '../../assets/images/logo.png';
import { menuClickedEvent } from '../../utils/analytics';
import { useTranslation } from 'react-i18next';

export const Header = ({ withShadow }) => {
  const [isNavVisible, setIsNavVisible] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [loading, setLoading] = useState(true);
  const { isSticky, promotionCode } = useContext(GlobalContext);
  const { t } = useTranslation();

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 700px)');
    mediaQuery.addEventListener('change', handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };

  }, []);

  const handleMediaQueryChange = mediaQuery => {
    if (mediaQuery.matches) {
      setIsNavVisible(false);
      setIsSmallScreen(true);
    } else {
      setIsNavVisible(true);
      setIsSmallScreen(false);
    }

    setLoading(false);
  };

  const handleToggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  const handleHiddenMenu = () => {
    setIsNavVisible(false);
  };

  const handleAnalyticsEvent = value => {
    menuClickedEvent(value);
  };

  const getPromotionCodeTemplate = () => {
    if (!promotionCode) {
      return;
    }

    const { discount } = promotionCode.codes.find(code => code.billingType === 'MONTLY');
    const promotionCodeText = `Hasta ${discount}% OFF en todos nuestros planes 🔥`;
    return <p className="header__promotion-code">{promotionCodeText}</p>;
  };

  if (loading) {
    return <></>;
  }

  return (
    <header className={`header ${isSticky ? 'sticky' : ''} ${withShadow ? 'with-shadow' : ''}`}>
      {getPromotionCodeTemplate()}
      <div className="wrapper-big">
        <section className="header__content">
          {(!isSmallScreen || isNavVisible) && (
            <nav className="nav">
              <ul className="nav__list">
                {menuOptions.map(
                  (menuOption, index) =>
                    menuOption.visibleIn.includes('HEADER') && (
                      <li className="nav__item" key={index} onClick={handleHiddenMenu}>
                        <Link
                          to={menuOption.url}
                          className="nav__link"
                          title={t(menuOption.name)}
                          onClick={() => handleAnalyticsEvent(menuOption.url)}
                        >
                          {t(menuOption.name)}
                        </Link>
                      </li>
                    ),
                )}
              </ul>
            </nav>
          )}
          <Link to="/" onClick={handleHiddenMenu}>
            <img src={pedixLogo} alt="Logo Pedix" className="header__logo" />
          </Link>
          {(!isSmallScreen || isNavVisible) && (
            <section className="header__buttons">
              <a
                href="https://pedix.app/acceso"
                target="_blank"
                onClick={() => handleAnalyticsEvent('Acceso a clientes')}
                rel="noreferrer"
              >
                <Button
                  type="primary"
                  size="medium"
                  className="header__button--admin"
                  onClick={handleHiddenMenu}
                >
                  {t('menu.access')}
                </Button>
              </a>
              <Link to="/#precios" onClick={() => handleAnalyticsEvent('Quiero empezar')}>
                <Button
                  type="secondary"
                  size="medium"
                  fill="solid"
                  className="header__button--start"
                  onClick={handleHiddenMenu}
                >
                  {t('general.buttonStart')}
                </Button>
              </Link>
            </section>
          )}
          <i
            className={`header__mobile-hamburger ${isNavVisible ? 'ci-close_big' : 'ci-hamburger'}`}
            onClick={handleToggleNav}
          ></i>
        </section>
      </div>
    </header>
  );
};
