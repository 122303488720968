export type APP_ESTABLISHMENT_SLUG = {
  value: string;
  source: 'domain' | 'subdomain' | 'path';
};

export const getAppEstablishmentSlug = (
  hostname: string,
  pathname: string,
  reservedSlugWords: string[],
) => {
  const hostnameParts = hostname.split('.');
  const subdomain = hostnameParts[0];
  const domain = hostnameParts.slice(1, hostnameParts.length - 1).join('.');
  const domainSuffix = hostnameParts[hostnameParts.length - 1];

  const [, slugInPath = ''] = pathname.split('/');

  // Checking for reserved slug + subdomain combination for cases in which we have a subdomain but we don't want it to be considered as slug
  // e.g.: 'comprarporwhatsapp-develop' or 'www'
  if (subdomain && domain && domainSuffix && !reservedSlugWords.includes(subdomain)) {
    return getEstablishmentSlugValue(subdomain, 'subdomain', reservedSlugWords);
  }
  return getEstablishmentSlugValue(slugInPath, 'path', reservedSlugWords);
};

export const getEstablishmentSlugValue = (
  slug: string,
  source: APP_ESTABLISHMENT_SLUG['source'],
  reservedSlugWords: string[],
): APP_ESTABLISHMENT_SLUG => {
  return {
    value: reservedSlugWords.includes(slug) ? '' : slug.toLowerCase(),
    source,
  };
};

export const reservedSlugWords = [
  'admin',
  'acceso',
  'assets',
  'usuarios',
  'registro',
  'login',
  'suscripcion',
  'suscripciones',
  'subscripciones',
  'planes',
  'demo',
  'private',
  'local',
  'comprarporwhatsapp-develop',
  'comprarporwhatsapp',
  'pedixapp-go7lmw5ccq-uc',
  'pedixapp-o2v5ctlc2q-uc',
  'localhost',
  '192',
  'not-found',
  'www',
  'pedix',
  'pedixapp',
  'map',
  'stripe',
  'boxful',
  'zenrise',
  'logs',
  'pedidos',
  'pedido',
  'centro-ayuda',
  'youtube',
  'instagram',
  'ayuda',
  'dev',
  'development',
  'tiendas',
  'stores',
  'blog',
  'mrc',
  'maxirest',
  'thinkion',
  'fudo',
  'menudigital',
  'menu',
  'pagos',
  'services',
  'worker-services',
];
