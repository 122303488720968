import React, { useContext, useEffect, useState } from 'react';
import Footer from './Footer';
import Header from './Header';
import AOS from 'aos';
import GlobalContext from '../context/GlobalContext';
import { Helmet } from 'react-helmet';
import { subscriptionWizardFiles } from '../constants';

export const Layout = ({ children, path }) => {
  const { isSticky } = useContext(GlobalContext);
  const [pagesWithShadow] = useState(['/terminos-y-condiciones/', '/politicas-de-privacidad/']);

  useEffect(() => {
    AOS.init({
      once: true,
    });
  }, []);

  return (
    <>
      <Helmet>
        <link
          href="https://cdn.zenrise.io/libraries/js/sdk/zenrise-sdk-latest.js"
          rel="preload"
          as="script"
        ></link>

        {subscriptionWizardFiles.map(file => (
          <link key={file.src} rel="preload" as={file.type} href={file.src} />
        ))}
      </Helmet>

      <Header withShadow={pagesWithShadow.includes(path)}></Header>
      <div style={{ overflowX: 'hidden' }}>
        <main style={{ marginTop: isSticky ? '-80px' : '0' }}>{children}</main>
        <Footer></Footer>
      </div>
    </>
  );
};
