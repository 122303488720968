import { LandingPromotions } from '@pedix-workspace/utils';
import { graphql, useStaticQuery } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useState } from 'react';
import Spinner from '../components/Spinner';
import { ICountryVisitor } from '../types';
import { getPromotionCode } from '../utils/promotionCodes';

declare const window: any;

const GlobalContext = React.createContext(null);

const GlobalProvider = ({ children, location }) => {
  const [countryVisitor, setCountryVisitor] = useState<ICountryVisitor>(null);
  const [isSticky, setIsSticky] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [promotionCode, setPromotionCode] = useState<LandingPromotions>(null);
  const { changeLanguage } = useI18next();
  const {
    allLandingPromotionCodes: { nodes: landingPromotionCodes },
  } = useStaticQuery(graphql`
    {
      allLandingPromotionCodes {
        nodes {
          promotionCode
          codes {
            billingType
            code
            discount
            quantityOfMonths
          }
          endDate
          startDate
        }
      }
    }
  `);

  const handleScroll = () => setIsSticky(window.scrollY > 300);

  const getCountryVisitorByIp = async () => {
    let countryVisitorResponse = null;
    const url = `${process.env.GEOLOCATION_URL}`;

    try {
      const response = await fetch(url);
      countryVisitorResponse = await response.json();
    } catch (error) {
      countryVisitorResponse = { country: 'XX' };
    }

    setCountryVisitor(countryVisitorResponse);

    const { country } = countryVisitorResponse;

    if (!['AR', 'UY'].includes(country) && !window.location.pathname.startsWith('/es')) {
      await changeLanguage('es');
    }

    return country;
  };

  const handleOpenChat = () => {
    if (!window.MessageBirdChatWidget?.isOpen) {
      window.MessageBirdChatWidget?.toggleChat();
    }
  };

  useEffect(() => {
    const getInitialData = async () => {
      const country = await getCountryVisitorByIp();
      setPromotionCode(getPromotionCode(landingPromotionCodes, country));
      setIsLoading(false);
    };

    getInitialData();

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <GlobalContext.Provider value={{ countryVisitor, isSticky, promotionCode, handleOpenChat }}>
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;
export { GlobalProvider };
