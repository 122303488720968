import { endOfDay, endOfMonth, endOfWeek, endOfYear, startOfDay, startOfMonth, startOfWeek, startOfYear, subDays } from "date-fns";
import { DateRange } from "./types";

export type DateRangeKey = 
  | 'TODAY'
  | 'YESTERDAY'
  | 'THIS_WEEK'
  | 'LAST_WEEK'
  | 'LAST_7_DAYS'
  | 'THIS_MONTH'
  | 'LAST_MONTH'
  | 'LAST_30_DAYS'
  | 'THIS_YEAR'
  | 'LAST_365_DAYS'
;

export const getDateRange = (dateRangeKey: DateRangeKey): DateRange => {
  const today = new Date();

  switch (dateRangeKey) {
    case 'TODAY': {
      return { from: startOfDay(today), to: endOfDay(today) };
    }
    case 'YESTERDAY': {
      const yesterday = subDays(today, 1);

      return { from: startOfDay(yesterday), to: endOfDay(yesterday) };
    }
    case 'THIS_WEEK': {
      const firstDayOfWeek = startOfWeek(today, { weekStartsOn: 1 });
      const lastDayOfWeek = endOfWeek(today, { weekStartsOn: 1 });

      return { from: startOfDay(firstDayOfWeek), to: endOfDay(lastDayOfWeek) };
    }
    case 'LAST_WEEK': {
      const lastWeekTo = subDays(startOfWeek(today, { weekStartsOn: 1 }), 1);
      const lastWeekFrom = subDays(lastWeekTo, 6);

      return { from: startOfDay(lastWeekFrom), to: endOfDay(lastWeekTo) };
    }
    case 'LAST_7_DAYS': {
      const sevenDaysFromNow = subDays(today, 6);

      return { from: startOfDay(sevenDaysFromNow), to: endOfDay(today) };
    }
    case 'THIS_MONTH': {
      const startOfMonthDay = startOfMonth(today);
      const endOfMonthDay = endOfMonth(today);

      return { from: startOfDay(startOfMonthDay), to: endOfDay(endOfMonthDay) };
    }
    case 'LAST_MONTH': {
      const lastMonthTo = subDays(startOfMonth(today), 1);
      const lastMonthFrom = startOfMonth(lastMonthTo);

      return { from: startOfDay(lastMonthFrom), to: endOfDay(lastMonthTo) };
    }
    case 'LAST_30_DAYS': {
      const last30DaysFrom = subDays(today, 29);

      return { from: startOfDay(last30DaysFrom), to: endOfDay(today) };
    }
    case 'THIS_YEAR': {
      const thisYearFrom = startOfYear(today);
      const thisYearTo = endOfYear(today);

      return { from: startOfDay(thisYearFrom), to: endOfDay(thisYearTo) };
    }
    case 'LAST_365_DAYS': {
      const last365DaysFrom = subDays(today, 364);

      return { from: startOfDay(last365DaysFrom), to: endOfDay(today) };
    }
    default: {
      throw new Error('Unexpected Date Range');
    }
  }
}