// If you don't want to use TypeScript you can delete this file!
import { graphql } from 'gatsby';
import * as React from 'react';
import Seo from '../components/seo';
import Community from '../sections/Community';
import Faqs from '../sections/Faqs';
import { Hero } from '../sections/Hero/Hero';
import { Information } from '../sections/Information/Information';
import Plans from '../sections/Plans';

import { Statistics } from '../sections/Statistics/Statistics';

import Steps from '../sections/Steps';

import Testimonials from '../sections/Testimonials';

const Home = () => {

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Pedix',
    url: 'https://pedix.app',
    logo: 'https://cdn.pedix.app/landing/logo-color.png',
    description: "Ahorra tiempo, vendé fácil y rápido con tu tienda en Pedix.",
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: '+5493517337251',
      contactType: 'customer service',
      areaServed: 'AR',
      availableLanguage: 'es',
    },
    sameAs: [
      'https://instagram.com/pedix_ok',
      'https://www.youtube.com/channel/UCW4GFBN3wKJP8mg9PtsUkAg',
      'https://pedix.app',
      'https://www.linkedin.com/company/pedixapp',
    ],
  };

  return (
    <>
      <Seo schema={schema} />
      <Hero></Hero>
      <Statistics></Statistics>
      <Information></Information>
      <Testimonials></Testimonials>
      <Steps></Steps>
      <Plans></Plans>
      <Faqs></Faqs>
      <Community></Community>
    </>
  );
};

export default Home;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

