import { StaticImage } from 'gatsby-plugin-image';
import React, { useEffect } from 'react';
import Button from '../../components/Button';
import TiendaDemo from '../../components/TiendaDemo';
import { pricingViewedEvent } from '../../utils/analytics';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

import './hero.scss';

export const Hero = () => {
  const { t } = useTranslation();

  return (
    <section className="hero">
      <div className="wrapper-medium">
        <section className="hero__content">
          <div className="decorations">
            <div className="hero__circle-gray"></div>
            <div className="hero__circle-pink"></div>
          </div>
          <section
            className="hero__value-proposal"
            data-aos="fade-right"
            data-aos-duration="750"
            data-aos-once="true"
            data-aos-delay="500"
          >
            <h1 className="h1">
              <Trans i18nKey="hero.title" components={{ newLine: <br /> }} />
            </h1>
            <p className="big hero__subtitle">
              { t('hero.subtitle') }
            </p>
            <section className="hero__buttons">
              <Link
                to="/#precios"
                onClick={() => pricingViewedEvent('top banner')}
              >
                <Button type="primary" fill="solid" size="large">
                  { t('general.buttonStart') }
                </Button>
              </Link>
              <TiendaDemo size="large" location="top banner"></TiendaDemo>
            </section>
          </section>
          <section
            className="hero__image"
            data-aos="fade-down"
            data-aos-duration="750"
            data-aos-once="true"
            data-aos-delay="500"
          >
            <figure className="hero__figure">
              <div
                className="hero__circle-yellow"
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-once="true"
                data-aos-delay="1000"
              ></div>
              <div
                className="hero__circle-cyan"
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-once="true"
                data-aos-delay="1000"
              ></div>
              <StaticImage
                src="../../assets/images/hero.png"
                alt="Chica sonriente utilizando Pedix"
                className="hero__girl-image"
                placeholder="blurred"
                quality={100}
              ></StaticImage>
            </figure>
          </section>
        </section>
      </div>
    </section>
  );
};
