import { marker as _ } from '@ngneat/transloco-keys-manager/marker.js';

interface IMenuOption {
  name: string;
  url: string;
  visibleIn: string[];
}

const menuOptions: IMenuOption[] = [
  {
    name: _('menu.howItWork'),
    url: '/#como-funciona',
    visibleIn: ['HEADER', 'FOOTER'],
  },
  {
    name: _('menu.prices'),
    url: '/#precios',
    visibleIn: ['HEADER', 'FOOTER'],
  },
  {
    name: _('menu.blog'),
    url: '/blog',
    visibleIn: [],
  },
  {
    name: _('menu.questions'),
    url: '/#preguntas-frecuentes',
    visibleIn: ['HEADER', 'FOOTER'],
  },
  {
    name: _('menu.termsAndConditions'),
    url: '/terminos-y-condiciones',
    visibleIn: ['FOOTER'],
  },
  {
    name: _('menu.privacity'),
    url: '/politicas-de-privacidad',
    visibleIn: ['FOOTER'],
  },
];

export { menuOptions };
