import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Button from '../../components/Button';
import Highlight from '../../components/Highlight';
import TiendaDemo from '../../components/TiendaDemo';
import phoneGif from '../../assets/images/phone.gif';
import phoneFramework from '../../assets/images/phone-framework.png';
import './information.scss';
import { Trans, useTranslation } from 'react-i18next';

export const Information = () => {
  const { t } = useTranslation();

  return (
    <section className="information">
      <div className="wrapper-medium">
        <section className="information__content">
          <section
            className="information__image"
            data-aos="fade-down"
            data-aos-duration="750"
            data-aos-once="true"
          >
            <div className="information__image-group">
              <Highlight>{t('general.highlight')}</Highlight>
              <section className="information__phone">
                <img src={phoneFramework} alt="" className="information__phone-framework" />
                <img src={phoneGif} alt="" className="information__phone-video" />
              </section>
              <StaticImage
                src="../../assets/images/rectangle.png"
                alt="Rectangulo"
                className="information__image-rectangle"
                placeholder="blurred"
                quality={100}
              ></StaticImage>
            </div>
          </section>
          <section
            className="information__info"
            data-aos="fade-right"
            data-aos-duration="750"
            data-aos-once="true"
            data-aos-delay="500"
          >
            <h3 className="h3">
              <Trans i18nKey="information.title" components={{ newLine: <br /> }} />
            </h3>
            <p className="information__subtitle big">
              <Trans
                i18nKey="information.subtitle"
                components={{ newLine: <br />, bold: <span className="bold"></span> }}
              />
            </p>
            <p className="information__text">{t('information.text')}</p>
            <TiendaDemo size="small" location="middle banner"></TiendaDemo>
          </section>
        </section>
      </div>
    </section>
  );
};
