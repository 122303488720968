export type ObfuscateEmailOptions = {
    accountMaxVisibilityLength?: number;
}

export function obfuscateEmail(email: string, { accountMaxVisibilityLength }: ObfuscateEmailOptions): string {
    const [account, domain] = email.split('@');
    const halfRoundUp = Math.ceil(account.length / 2);
    const clearCharactersQty = Math.min(halfRoundUp, accountMaxVisibilityLength);
    const obfuscatedAccount = account.substr(0, clearCharactersQty).padEnd(account.length, '*');

    return `${obfuscatedAccount}@${domain}`;
}
