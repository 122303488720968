import { AddressDetails, CustomAddressComponents } from '@pedix-workspace/utils';

export function getAddressDetails(
  customAddressComponents: CustomAddressComponents,
  place: google.maps.places.PlaceResult | google.maps.GeocoderResult,
  geolocation?: google.maps.LatLngLiteral,
): AddressDetails {
  const addressComponents = place.address_components || [];
  const useCustomLocation = Boolean(geolocation);
  const freeSearch = customAddressComponents.freeSearch;

  const street = addressComponents.find(component => component.types.includes('route'))?.short_name;
  const streetNumber = addressComponents.find(component => component.types.includes('street_number'))?.short_name;

  const state = addressComponents.find(component =>
    component.types.includes('administrative_area_level_1'),
  )?.short_name;
  const locality = addressComponents.find(component => component.types.includes('locality'))
    ?.long_name;
  const country = addressComponents.find(component => component.types.includes('country'))
    ?.long_name;
  const postalCode = addressComponents.find(component => component.types.includes('postal_code'))
    ?.short_name;
  const neighborhood = addressComponents.find(component => component.types.includes('neighborhood'))
    ?.short_name;
  const intersection = addressComponents.find(component => component.types.includes('intersection'))
    ?.short_name;

  const lat = geolocation ? geolocation.lat : place.geometry.location.lat();
  const lng = geolocation ? geolocation.lng : place.geometry.location.lng();
  const coordinates: google.maps.LatLngLiteral = { lat, lng };

  const fullAddressComponents = [];

  if (useCustomLocation && freeSearch) {
    fullAddressComponents.push(freeSearch);
  } else if (street) {
    fullAddressComponents.push(streetNumber ? `${street} ${streetNumber}` : street);
  } else if (intersection) {
    fullAddressComponents.push(intersection);
  }
  if (locality) {
    fullAddressComponents.push(locality);
  }
  if (state) {
    fullAddressComponents.push(state);
  }
  if (country) {
    fullAddressComponents.push(country);
  }

  const fullAddress = fullAddressComponents.join(', ');
  const urlQuery = useCustomLocation ? `${lat},${lng}` : encodeURI(fullAddress.replace(/ /g, '+'));
  const url = `https://maps.google.com/?q=${urlQuery}`;

  return {
    freeSearch,
    fullAddress,
    url,
    street,
    streetNumber,
    state,
    locality,
    country,
    postalCode,
    neighborhood,
    coordinates,
    useCustomLocation,
  };
}
