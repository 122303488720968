import React from 'react';
import { useTranslation } from 'react-i18next';
import './highlight.scss';

export const Highlight = ({ children }) => {
  const { t } = useTranslation();

  return (
    <section className="highlight">
      <div className="highlight__content">
        <section className="highlight__icon-content">
          <figure className="highlight__figure">
            <i className="ci-happy highlight__icon"></i>
          </figure>
        </section>
        <section className="highlight__info">
          <h5 className="highlight__title h5">{t('general.highlightHeader')}</h5>
          <p className="highlight__text h4">{children}</p>
        </section>
      </div>
    </section>
  );
};
