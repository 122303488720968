import React from 'react';

import './src/styles/global.scss';

import 'aos/dist/aos.css';

import { Layout } from './src/components/Layout';
import { GlobalProvider } from './src/context/GlobalContext';

export const wrapPageElement = ({ element }) => {
  const newElement = React.cloneElement(
    element, // I18nextProvider
    element.props,
    React.cloneElement(
      element.props.children, // I18nextContext.Provider
      element.props.children.props,
      React.createElement(Layout, undefined, element.props.children.props.children),
    ),
  );

  return newElement;
};

export const wrapRootElement = ({ element }) => <GlobalProvider>{element}</GlobalProvider>;
