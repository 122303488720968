import React from 'react';
import './circles-decoration.scss';

export const CirclesDecoration = () => {
  return (
    <section className="circles-decoration">
      <section
        className="circles-decoration__circles-right"
        data-aos="fade-down"
        data-aos-duration="1000"
        data-aos-once="true"
        data-aos-delay="600"
      >
        <div className="circles-right__cyan"></div>
        <div className="circles-right__yellow"></div>
        <div className="circles-right__orange"></div>
      </section>
      <section
        className="circles-decoration__circles-left"
        data-aos="fade-left"
        data-aos-duration="1000"
        data-aos-once="true"
        data-aos-delay="500"
      >
        <div className="circles-left__gray"></div>
      </section>
    </section>
  );
};
