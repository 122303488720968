import React, { useRef, useState } from 'react';
import './testimonials-carousel.scss';
import BabuImage from '../../assets/images/testimonials/babu.jpg';
import RicasoImage from '../../assets/images/testimonials/ricaso.jpg';
import OlstoreImage from '../../assets/images/testimonials/olstore.jpg';
import MandarinaImage from '../../assets/images/testimonials/mandarina.jpg';
import QuoteImage from '../../assets/images/comillas.svg';
import { SwiperSlide, Swiper } from 'swiper/react';
// import Swiper core and required modules
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';

// install Swiper modules
SwiperCore.use([Autoplay, Navigation, Pagination]);

interface ITestominalsCarousel {
  photo: string;
  text: string;
  name: string;
  establishment: string;
  instagramLink: string;
  colorQuote: string;
}

export const TestimonialsCarousel = () => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const { t } = useTranslation()

  const [testimonials, setTestimonials] = useState<ITestominalsCarousel[]>([
    {
      photo: BabuImage,
      text: t('testimonials.testimonialOne'),
      establishment: '@babu.bebes',
      instagramLink: 'https://www.instagram.com/babu.bebes/',
      name: 'Euge',
      colorQuote: '#FFD082',
    },
    {
      photo: RicasoImage,
      text: t('testimonials.testimonialTwo'),
      establishment: '@ricasocba',
      instagramLink: 'https://www.instagram.com/ricasocba/',
      name: 'Mariano',
      colorQuote: '#89D0DE',
    },
    {
      photo: MandarinaImage,
      text: t('testimonials.testimonialThree'),
      establishment: '@desayunosmandarina',
      instagramLink: 'https://www.instagram.com/desayunosmandarina_cordoba/',
      name: 'Juan',
      colorQuote: '#F57D7E',
    },
    {
      photo: OlstoreImage,
      text: t('testimonials.testimonialFour'),
      establishment: '@olstorecervezas',
      instagramLink: 'https://www.instagram.com/olstorecervezas/',
      name: 'Juan',
      colorQuote: '#3B3C66',
    },
  ]);

  return (
    <section className="testimonials-carousel">
      <Swiper
        pagination={{
          el: '.testimonial__pagination',
          clickable: true,
        }}
        breakpoints={{
          0: {
            slidesPerView: 1.2,
            spaceBetween: 25,
          },
          450: {
            slidesPerView: 1,
          },
        }}
        navigation={{
          nextEl: '.testimonial__button--next',
          prevEl: '.testimonial__button--prev',
        }}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
      >
        {testimonials.map((testimonial, index) => (
          <SwiperSlide key={index}>
            <section className="testimonial">
              <figure>
                <img src={testimonial.photo} alt="" className="testimonial__image" />
              </figure>
              <section className="testimonial__content">
                <svg
                  width="28"
                  height="20"
                  viewBox="0 0 28 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8 20H2L6 12H0V0H12V12L8 20ZM24 20H18L22 12H16V0H28V12L24 20Z"
                    fill={testimonial.colorQuote}
                  />
                </svg>

                <p className="testimonial__text">{testimonial.text}</p>
                <h6 className="h6 testimonial__name">{testimonial.name}</h6>
                <p className="testimonial__establishment big">
                  <a
                    href={testimonial.instagramLink}
                    target="_blank"
                    rel="noreferrer"
                    className="link"
                  >
                    {testimonial.establishment}
                  </a>
                </p>
              </section>
            </section>
          </SwiperSlide>
        ))}
      </Swiper>
      <button className="testimonial__button testimonial__button--next">
        <i className="ci-short_right"></i>
      </button>
      <button className="testimonial__button testimonial__button--prev">
        <i className="ci-short_left"></i>{' '}
      </button>
      <div className="testimonial__pagination"></div>
    </section>
  );
};
