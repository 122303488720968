import React from 'react';
import './button.scss';

interface Button {
  type: 'primary' | 'secondary' | 'with-arrow' | 'with-arrow-left';
  size?: 'small' | 'medium' | 'large';
  fill?: 'solid' | 'transparent';
  color?: 'pink';
  className?: string;
  onClick?: () => unknown;
  children: React.ReactNode;
}

export const Button: React.FC<Button> = ({
  children,
  type,
  size,
  fill,
  className,
  onClick,
  color,
}) => {
  return (
    <button
      className={`${type ? type : ''} ${size ? size : ''} ${color ? color : ''} ${
        fill ? fill : ''
      } ${className ? className : ''}`}
      onClick={onClick}
    >
      {type === 'with-arrow-left' && <i className="ci-short_left arrow"></i>}
      {children}
      {type === 'with-arrow' && <i className="ci-short_right arrow"></i>}
    </button>
  );
};
