import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import { pricingViewedEvent } from '../../utils/analytics';
import './steps.scss';

export const Steps = () => {
  const { t } = useTranslation();

  return (
    <section className="steps" id="como-funciona">
      <div className="wrapper-medium">
        <section
          className="steps__content"
          data-aos="fade-right"
          data-aos-duration="750"
          data-aos-once="true"
          data-aos-delay="500"
        >
          <h2 className="h2 steps__title">{t('steps.title')}</h2>
          <section className="steps__step-wrapper">
            <section className="step-landing">
              <figure className="step-landing__figure">
                <StaticImage
                  src="../../assets/images/step-1.jpg"
                  alt={t('steps.stepOne')}
                  className="step-landing__image"
                  placeholder="blurred"
                  quality={100}
                ></StaticImage>
              </figure>
              <section className="step-landing__content">
                <h5 className="h5 step-landing__step">{t('steps.stepOne')}</h5>
                <h3 className="h3">
                  <Trans
                    i18nKey="steps.stepOneTitle"
                    components={{ normal: <span className="normal"></span> }}
                  />
                </h3>
                <p>{t('steps.stepOneContent')}</p>
                <Link
                  to="/#precios"
                  onClick={() => pricingViewedEvent('middle banner')}
                >
                  <Button type="with-arrow">{t('steps.viewPlans')}</Button>
                </Link>
              </section>
            </section>
            <section className="step-landing">
              <figure className="step-landing__figure">
                <StaticImage
                  src="../../assets/images/step-2.jpg"
                  alt={t('steps.stepTwo')}
                  className="step-landing__image"
                ></StaticImage>
              </figure>
              <section className="step-landing__content">
                <h5 className="h5">{t('steps.stepTwo')}</h5>
                <h3 className="h3">
                  <Trans
                    i18nKey="steps.stepTwoTitle"
                    components={{ normal: <span className="normal"></span> }}
                  />
                </h3>
                <p>{t('steps.stepTwoContent')}</p>
              </section>
            </section>
            <section className="step-landing">
              <figure className="step-landing__figure">
                <StaticImage
                  src="../../assets/images/step-3.jpg"
                  alt={t('steps.stepThree')}
                  className="step-landing__image"
                ></StaticImage>
              </figure>
              <section className="step-landing__content">
                <h5 className="h5">{t('steps.stepThree')}</h5>
                <h3 className="h3">
                  <Trans
                    i18nKey="steps.stepThreeTitle"
                    components={{ normal: <span className="normal"></span> }}
                  />{' '}
                </h3>
                <p>{t('steps.stepThreeContent')}</p>
              </section>
            </section>
          </section>
        </section>
      </div>
    </section>
  );
};
