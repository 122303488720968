import { marker as _ } from '@ngneat/transloco-keys-manager/marker.js';

import { PlanFeature } from './types';

export const quantityImagesAvailablePerPlan = {
  BASIC: 1,
  INTERMEDIATE: 3,
  ADVANCED: 10,
};

export const stockPerPlan = {
  BASIC: _('features.stockBasic'),
  INTERMEDIATE: _('features.stockAdvanced'),
  ADVANCED: _('features.stockAdvanced'),
};

export const featuresList: PlanFeature[] = [
  {
    name: _('features.orderReception'),
    planDetails: {
      BASIC: {
        display: true,
      },
      INTERMEDIATE: {
        display: true,
      },
      ADVANCED: {
        display: true,
      },
    },
  },
  {
    name: _('features.unlimitedOrdersCategoriesAndProducts'),
    planDetails: {
      BASIC: {
        display: true,
      },
      INTERMEDIATE: {
        display: true,
      },
      ADVANCED: {
        display: true,
      },
    },
  },
  {
    name: _('features.orderCenter'),
    planDetails: {
      BASIC: {
        display: true,
      },
      INTERMEDIATE: {
        display: true,
      },
      ADVANCED: {
        display: true,
      },
    },
  },
  {
    name: _('features.orderPrint'),
    planDetails: {
      BASIC: {
        display: true,
      },
      INTERMEDIATE: {
        display: true,
      },
      ADVANCED: {
        display: true,
      },
    },
  },
  {
    name: _('features.customizableProfile'),
    planDetails: {
      BASIC: {
        display: true,
      },
      INTERMEDIATE: {
        display: true,
      },
      ADVANCED: {
        display: true,
      },
    },
  },
  {
    name: _('features.digitalCatalog'),
    planDetails: {
      BASIC: {
        display: true,
      },
      INTERMEDIATE: {
        display: true,
      },
      ADVANCED: {
        display: true,
      },
    },
  },
  {
    name: _('features.extraProductOptions'),
    planDetails: {
      BASIC: {
        display: true,
      },
      INTERMEDIATE: {
        display: true,
      },
      ADVANCED: {
        display: true,
      },
    },
  },
  {
    name: _('features.gallery'),
    planDetails: {
      BASIC: {
        display: true,
        text: _('features.galleryBasic'),
      },
      INTERMEDIATE: {
        display: true,
        text: _('features.galleryQuantity'),
        config: {
          quantity: quantityImagesAvailablePerPlan['INTERMEDIATE'],
        },
      },
      ADVANCED: {
        display: true,
        text: _('features.galleryQuantity'),
        config: {
          quantity: quantityImagesAvailablePerPlan['ADVANCED'],
        },
      },
    },
  },
  {
    name: _('features.stock'),
    planDetails: {
      BASIC: {
        display: true,
        text: _('features.stockBasic'),
      },
      INTERMEDIATE: {
        display: true,
        text: _('features.stockAdvanced'),
      },
      ADVANCED: {
        display: true,
        text: _('features.stockAdvanced'),
      },
    },
  },
  {
    name: _('features.discountCoupons'),
    planDetails: {
      BASIC: {
        display: true,
      },
      INTERMEDIATE: {
        display: true,
      },
      ADVANCED: {
        display: true,
      },
    },
  },
  {
    name: _('features.currencySettings'),
    planDetails: {
      BASIC: {
        display: true,
      },
      INTERMEDIATE: {
        display: true,
      },
      ADVANCED: {
        display: true,
      },
    },
  },

  {
    name: _('features.altLanguages'),
    planDetails: {
      BASIC: {
        display: true,
      },
      INTERMEDIATE: {
        display: true,
      },
      ADVANCED: {
        display: true,
      },
    },
  },

  {
    name: _('features.configurationHours'),
    planDetails: {
      BASIC: {
        display: true,
      },
      INTERMEDIATE: {
        display: true,
      },
      ADVANCED: {
        display: true,
      },
    },
  },
  {
    name: _('features.qrCode'),
    planDetails: {
      BASIC: {
        display: true,
      },
      INTERMEDIATE: {
        display: true,
      },
      ADVANCED: {
        display: true,
      },
    },
  },
  {
    name: _('features.statistics'),
    planDetails: {
      BASIC: {
        display: true,
      },
      INTERMEDIATE: {
        display: true,
      },
      ADVANCED: {
        display: true,
      },
    },
  },

  {
    name: _('features.paymentGatewayIntegration'),
    planDetails: {
      BASIC: {
        display: true,
      },
      INTERMEDIATE: {
        display: true,
      },
      ADVANCED: {
        display: true,
      },
    },
  },
  {
    name: _('features.configurationTimeDaysCategories'),
    planDetails: {
      BASIC: {
        display: false,
      },
      INTERMEDIATE: {
        display: true,
      },
      ADVANCED: {
        display: true,
      },
    },
  },
  {
    name: _('features.googleAnalytics'),
    planDetails: {
      BASIC: {
        display: false,
      },
      INTERMEDIATE: {
        display: true,
      },
      ADVANCED: {
        display: true,
      },
    },
  },
  {
    name: _('features.facebookPixel'),
    planDetails: {
      BASIC: {
        display: false,
      },
      INTERMEDIATE: {
        display: true,
      },
      ADVANCED: {
        display: true,
      },
    },
  },
  {
    name: _('features.dynamicCategories'),
    planDetails: {
      BASIC: {
        display: false,
      },
      INTERMEDIATE: {
        display: true,
      },
      ADVANCED: {
        display: true,
      },
    },
  },
  {
    name: _('features.shippingPrice'),
    planDetails: {
      BASIC: {
        display: false,
      },
      INTERMEDIATE: {
        display: false,
      },
      ADVANCED: {
        display: true,
      },
    },
  },
  {
    name: _('features.geolocation'),
    planDetails: {
      BASIC: {
        display: false,
      },
      INTERMEDIATE: {
        display: false,
      },
      ADVANCED: {
        display: true,
      },
    },
  },
  {
    name: _('features.bulkEdit'),
    planDetails: {
      BASIC: {
        display: false,
      },
      INTERMEDIATE: {
        display: false,
      },
      ADVANCED: {
        display: true,
      },
    },
  },
  {
    name: _('features.advancedStatistics'),
    planDetails: {
      BASIC: {
        display: false,
      },
      INTERMEDIATE: {
        display: false,
      },
      ADVANCED: {
        display: true,
      },
    },
  },
  {
    name: _('features.userManagement'),
    planDetails: {
      BASIC: {
        display: false,
      },
      INTERMEDIATE: {
        display: false,
      },
      ADVANCED: {
        display: true,
      },
    },
  },
  {
    name: _('features.subsidiaries'),
    planDetails: {
      BASIC: {
        display: true,
        custom: true,
      },
      INTERMEDIATE: {
        display: true,
        custom: true,
      },
      ADVANCED: {
        display: true,
        custom: true,
      },
    },
  },
  {
    name: _('features.customDomain'),
    planDetails: {
      BASIC: {
        display: true,
        text: _('features.customDomainAdvanced'),
      },
      INTERMEDIATE: {
        display: true,
        text: _('features.customDomainAdvanced'),
      },
      ADVANCED: {
        display: true,
        text: _('features.customDomainAdvanced'),
      },
      ADVANCED_ANNUAL: {
        display: true,
      },
    },
  },
];
